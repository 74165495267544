import { createEntityQuery, createQuery, filterNilValue, getStoreByName } from '@datorama/akita';
import { diff } from 'deep-object-diff';
import { isEmpty, omit, pickBy } from 'lodash-es';
import { of, switchMap, tap } from 'rxjs';
import { ApprovalStatus } from 'utils/enum';
import store from './store';

export const query = createQuery(store);

export const history$ = query.select(['storeName', 'entityId']).pipe(
   tap(({ storeName, entityId }) => {
      if (storeName && entityId) {
         const relevantStore = getStoreByName(storeName);

         if (relevantStore) {
            const storeQuery = createEntityQuery(relevantStore);

            storeQuery
               .selectAll({ limitTo: 1, filterBy: ({ id, history }) => id === entityId && Array.isArray(history) })
               .pipe(
                  filterNilValue(),
                  tap(() => store.setLoading(false))
               )
               .subscribe();
         } else store.setLoading(true);
      } else store.setLoading(true);
   }),
   switchMap(({ storeName, entityId }) => {
      if (storeName && entityId) {
         const relevantStore = getStoreByName(storeName);

         if (relevantStore) {
            const storeQuery = createEntityQuery(relevantStore);

            return storeQuery.selectEntity(entityId, ({ history }) =>
               Array.isArray(history)
                  ? history
                       .map((historyEntry) => {
                          const oldValue =
                             typeof (historyEntry?.oldValue ?? '{}') === 'string'
                                ? JSON.parse(historyEntry?.oldValue ?? '{}')
                                : { ...(historyEntry?.oldValue ?? {}) };

                          const newValue =
                             typeof (historyEntry?.newValue ?? '{}') === 'string'
                                ? JSON.parse(historyEntry?.newValue ?? '{}')
                                : { ...(historyEntry?.newValue ?? {}) };

                          const timeline = diff(oldValue, newValue);

                          const changed = {
                             ...omit(timeline, ['justification']),
                             ...('justification' in timeline ? { justification: timeline.justification } : {}),
                          };

                          const localizedKeyNames = [
                             'name',
                             'desc',
                             'shortDesc',
                             'recommendation',
                             'placeholder',
                             'guidance',
                             'auditorGuidance',
                             'individualGuidance',
                          ];

                          const newObj = { ...pickBy(historyEntry, (attr) => attr !== undefined && attr !== null), changed };

                          localizedKeyNames.forEach((localizedKey) => {
                             const localizedKeys = Object.keys(timeline).filter((key) => key.startsWith(`${localizedKey}_`));
                             const areValuesEqual =
                                localizedKeys.length > 0 && localizedKeys.every((key) => timeline[key] === timeline[localizedKeys[0]]);

                             const oldValuesEqual =
                                localizedKeys.length > 0 && localizedKeys.every((key) => oldValue[key] === oldValue[localizedKeys[0]]);

                             const newValuesEqual =
                                localizedKeys.length > 0 && localizedKeys.every((key) => newValue[key] === newValue[localizedKeys[0]]);

                             if (areValuesEqual) {
                                newObj[localizedKey] = timeline[localizedKeys[0]];
                                localizedKeys.forEach((keytoDelete) => delete newObj[keytoDelete]);
                             }

                             if (newValuesEqual && oldValuesEqual) {
                                newValue[localizedKey] = newValue[localizedKeys[0]];
                                localizedKeys.forEach((keytoDelete) => delete newValue[keytoDelete]);

                                oldValue[localizedKey] = oldValue[localizedKeys[0]];
                                localizedKeys.forEach((keytoDelete) => delete oldValue[keytoDelete]);

                                newObj.changed[localizedKey] = newObj.changed[localizedKeys[0]];
                                localizedKeys.forEach((keytoDelete) => delete newObj.changed[keytoDelete]);
                             }
                          });

                          if (oldValue?.status !== newValue?.status) {
                             const statusChange = `${oldValue.status ?? ''}->${newValue.status}`;

                             let statusDesc;

                             switch (statusChange) {
                                case 'IN_PROGRESS->REVIEWED':
                                   statusDesc = ApprovalStatus.REVIEWED;
                                   break;
                                case 'REVIEWED->APPROVED':
                                   statusDesc = ApprovalStatus.APPROVED;
                                   break;
                                case 'APPROVED->ARCHIVED':
                                   statusDesc = ApprovalStatus.ARCHIVED;
                                   break;
                                case 'ARCHIVED->APPROVED':
                                   statusDesc = 'UNARCHIVE';
                                   break;
                                case 'APPROVED->REVIEWED':
                                   statusDesc = 'UNAPPROVE';
                                   break;
                                case 'REVIEWED->IN_PROGRESS':
                                   statusDesc = 'UNREVIEW';
                                   break;
                                default:
                                   break;
                             }

                             newObj.kind = (statusDesc ?? newObj?.kind ?? newObj?.desc ?? '').toUpperCase();
                          }

                          return omit(
                             {
                                ...newObj,
                                oldValue,
                                newValue,
                             },
                             ['slug', 'createdAt', 'level', 'organisationId', 'objectId']
                          );
                       })
                       .filter(
                          (historyEntry) =>
                             !isEmpty(historyEntry.changed) &&
                             !(
                                Object.keys(historyEntry.oldValue).length <= 2 &&
                                Object.keys(historyEntry.oldValue).includes('status') &&
                                historyEntry.table === 'businessactivity_taxonomyactivity'
                             )
                       )
                  : []
            );
         }
      }
      return of([]);
   })
);

export const loading$ = query.selectLoading();
