import { useObservableState } from 'observable-hooks';
import { useContext } from 'react';
import { UserContext } from 'context/index';
import facade from 'context/UserContext/facade';

export default function useUserContext() {
  const underlyingContext = useContext(UserContext);
  const context = useObservableState(underlyingContext);
  const userId = useObservableState(facade.query.select('id'));
  const notifications = useObservableState(facade.notifications$, []);
  const organisations = useObservableState(facade.organisations$, []);
  const reportingStandards = useObservableState(facade.reportingStandards$, []);
  const reportingPeriods = useObservableState(facade.reportingPeriods$, []);
  const isLoading = useObservableState(facade.loading$, true);
  const error = useObservableState(facade.query.selectError());

  return { context, isLoading, error, api: facade.api, userId, notifications, organisations, reportingStandards, reportingPeriods };
}
