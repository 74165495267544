import { applyTransaction, arrayRemove, arrayUpdate, combineQueries, filterNilValue, resetStores } from '@datorama/akita';
import ability from 'casl/ability';
import { pageSize$, userContextQuery } from 'context/UserContext/query';
import userContextStore from 'context/UserContext/store';
import { pick } from 'lodash-es';
import { debounceTime, distinctUntilChanged, map, of, tap } from 'rxjs';
import CRUDService from 'state/CRUDService';
import { query as uiQuery } from 'state/UI/query';
import uiStore from 'state/UI/store';
import { query } from 'state/User/query';
import store from 'state/User/store';
import baseClient from 'utils/axiosBaseClient';

const persistableViewStateProperties = [
   'activeDashboardTab',
   'organisationsView',
   'organisationsViewTopbar',
   'mainNavigationOpen',
   'keyFigureReportReportingStandardIds',
   'reportGroupByOrganisation',
   'reportShowEmptyFields',
   'reportOnlyApproved',
];

export default class UsersService extends CRUDService {
   constructor() {
      if (!UsersService.instance) {
         super('users', store, query, [], true, false, false, false, true, true);
         this.contextStore = userContextStore;
         this.uiStore = uiStore;
         this.baseClient = baseClient;
         this.userContextQuery = userContextQuery;
         this.hasLoaded = false;
         this.userPromise = undefined;

         UsersService.instance = this;
      }

      // UsersService shall be instantiated only once, because otherwise the observable will be created for each service instance
      // eslint-disable-next-line no-constructor-return
      return UsersService.instance;
   }

   async logout() {
      try {
         await this.persistViewState();
         this.resetHasLoaded();

         await this.baseClient.post('/auth/logout');
         resetStores({ exclude: ['design', 'applicationContext', 'loginProviders'] });

         return true;
      } catch (err) {
         return false;
      }
   }

   async getLoggedInUser() {
      this.contextStore.setLoading(true);
      if (this.userPromise) return this.userPromise;
      this.userPromise = this.httpClient
         .get(`/${this.version}/users/self`)
         .then((resp) => {
            this.appendDataToStore(resp.data, this.contextStore);

            ability.update(resp.data.rules);

            uiStore.update(resp.data.viewState);

            this.contextStore.setLoading(false);
            this.userPromise = undefined;
            return resp.data;
         })
         .catch((error) => {
            if (error?.response?.status === 401) {
               this.hasLoaded = true;
            } else {
               this.setError(error);
               this.contextStore.setLoading(false);
               this.userPromise = undefined;
            }
            return false;
         });

      return this.userPromise;
   }

   resetHasLoaded() {
      this.hasLoaded = false;
   }

   async isAuthenticated() {
      let storeValue = this.userContextQuery.getValue();

      if (storeValue?.id) return true;
      if (this.hasLoaded) return false;

      const isAuthenticated = await this.getLoggedInUser();

      if (isAuthenticated === false) return false;
      storeValue = this.userContextQuery.getValue();
      return !!storeValue?.id;
   }

   setActiveOrganisation = async (organisationId) => {
      const userId = userContextQuery.getValue().id;

      this.contextStore.setLoading(true);

      return this.httpClient
         .patch(`/${this.version}/users/${userId}`, {
            activeOrganisationId: organisationId,
         })
         .then((resp) =>
            applyTransaction(() => {
               this.contextStore.update((prevState) => ({
                  ...prevState,
                  ...resp.data,
               }));

               this.contextStore.setLoading(false);
            })
         )
         .catch((error) => {
            this.setError(error, this.contextStore);
            this.contextStore.setLoading(false);
         });
   };

   markNotificationAsRead = async (notificationId) => {
      const collectionName = 'notifications';

      return this.httpClient
         .post(`/${this.version}/notifications/${notificationId}/markasread`)
         .then(() =>
            this.contextStore.update(({ [collectionName]: collectionName_ }) => ({
               notifications: arrayUpdate(collectionName_, notificationId, { isRead: true }),
            }))
         )
         .catch((error) => {
            this.setError(error, this.contextStore);
         });
   };

   markAllNotificationsAsRead = async () => {
      const collectionName = 'notifications';
      const userId = userContextQuery.getValue().id;

      return this.httpClient
         .post(`/${this.version}/users/${userId}/notifications/readall`)
         .then(() =>
            this.contextStore.update(({ [collectionName]: collectionName_ }) => ({
               notifications: arrayUpdate(collectionName_, () => true, { isRead: true }),
            }))
         )
         .catch((error) => {
            this.setError(error, this.contextStore);
         });
   };

   deleteNotification = async (notificationId) => {
      const collectionName = 'notifications';

      return this.httpClient
         .delete(`/${this.version}/${collectionName}/${notificationId}`)
         .then(() =>
            this.contextStore.update(({ [collectionName]: collectionName_ }) => ({
               [collectionName]: arrayRemove(collectionName_, notificationId),
            }))
         )
         .catch((error) => {
            this.setError(error, this.contextStore);
         });
   };

   setActivePeriod = async (periodId) => {
      this.contextStore.setLoading(true);

      const userId = userContextQuery.getValue().id;

      return this.httpClient
         .patch(`/${this.version}/users/${userId}`, {
            activePeriodId: periodId,
         })
         .then((resp) => {
            this.contextStore.update((prevState) => ({
               ...prevState,
               ...resp.data,
            }));
            return this.contextStore.setLoading(false);
         })
         .catch((error) => {
            this.setError(error, this.contextStore);
            this.contextStore.setLoading(false);
         });
   };

   setIncludeSubsidiaries = async (includeSubsidiaries) => {
      this.contextStore.setLoading(true);

      const userId = userContextQuery.getValue().id;

      return this.httpClient
         .patch(`/${this.version}/users/${userId}`, {
            includeSubsidiaries,
         })
         .then((resp) => {
            this.contextStore.update((prevState) => ({
               ...prevState,
               ...resp.data,
            }));
            return this.contextStore.setLoading(false);
         })
         .catch((error) => {
            this.setError(error, this.contextStore);
            this.contextStore.setLoading(false);
         });
   };

   persistViewState = async () => {
      const userId = userContextQuery.getValue()?.id;

      if (userId) {
         const lastViewState = userContextQuery.getValue()?.viewState ?? {};
         const currentViewState = pick(uiStore.getValue(), persistableViewStateProperties);

         if (currentViewState?.previousOrganisationId) currentViewState.activeOrganisationId = currentViewState.previousOrganisationId;

         return this.httpClient.patch(`/${this.version}/users/${userId}`, {
            viewState: {
               ...lastViewState,
               ...currentViewState,
            },
         });
      }
   };

   setLayout = async (tabName, layout) => {
      if (
         Array.isArray(layout) &&
         layout.length > 0 &&
         !layout.every((layoutItem, index) => layoutItem.w === 1 && layoutItem.h === 1 && layoutItem.x === 0 && layoutItem.y === index)
      ) {
         const userLayout = userContextQuery.getValue()?.layouts ?? {};
         const activeOrganisationId = userContextQuery.getValue().activeOrganisation.id;

         if (
            !userLayout?.[activeOrganisationId] ||
            !(tabName in userLayout[activeOrganisationId]) ||
            layout.some((newLayout) => {
               const previousLayout = userLayout?.[activeOrganisationId]?.[tabName].find((prevLayout) => prevLayout.i === newLayout.i);

               return (
                  previousLayout?.x !== newLayout?.x ||
                  previousLayout?.y !== newLayout?.y ||
                  previousLayout?.w !== newLayout?.w ||
                  previousLayout?.h !== newLayout?.h
               );
            })
         ) {
            this.contextStore.setLoading(true);

            const userId = userContextQuery.getValue().id;

            return this.httpClient
               .patch(`/${this.version}/users/${userId}`, {
                  layouts: {
                     ...userLayout,
                     [activeOrganisationId]: {
                        [tabName]: layout,
                     },
                  },
               })
               .then((resp) => {
                  this.appendDataToStore(resp.data, this.contextStore);
                  return this.contextStore.setLoading(false);
               })
               .catch((error) => {
                  this.setError(error, this.contextStore);
                  this.contextStore.setLoading(false);
               });
         }
      }

      return undefined;
   };

   async updateUserImage(file, userId) {
      const formData = new FormData();
      formData.append('image', file[0]);

      return this.httpClient
         .post(`/${this.version}/users/${userId}/image`, formData, { 'Content-Type': 'multipart/form-data' })
         .then((resp) => {
            if (userContextQuery.getValue().id === userId) this.contextStore.update(resp.data);
            return this.store.update(userId, resp.data);
         })
         .catch((error) => this.setError(error, this.contextStore));
   }

   async setPageSize(pageSize) {
      const userId = userContextQuery.getValue().id;

      return this.httpClient
         .patch(`/${this.version}/users/${userId}`, { pageSize })
         .then((resp) => {
            if (userContextQuery.getValue().id === userId) this.contextStore.update(resp.data);
            return this.store.update(userId, resp.data);
         })
         .catch((error) => this.setError(error, this.contextStore));
   }

   async toggleNotificationTypeActive(userId, notificationType, active) {
      await this.updateEntityCollection(userId, 'notificationSettings', 'all', { [notificationType]: active });
      this.updateUserContextIfModified(userId);
   }

   async updateNotifications(userId, notificationType, notificationValue, notificationId) {
      await this.updateEntityCollection(userId, 'notificationSettings', notificationId, { [notificationType]: notificationValue });
      this.updateUserContextIfModified(userId);
   }

   async updateUserContextIfModified(entityId) {
      const userId = userContextQuery.getValue().id;
      if (entityId === userId) this.getLoggedInUser();
   }

   async updateEntity(entityId, changes) {
      await super.updateEntity(entityId, changes);
      this.updateUserContextIfModified(entityId);
   }

   showTutorial = async () => {
      const userId = userContextQuery.getValue().id;
      this.contextStore.update(() => ({ tutorialDone: false }));
      return Promise.resolve(this.updateEntity(userId, { tutorialDone: false }));
   };

   hideTutorial = async () => {
      const userId = userContextQuery.getValue().id;
      this.contextStore.update(() => ({ tutorialDone: true, tutorialStep: 0 }));
      return Promise.resolve(this.updateEntity(userId, { tutorialDone: true }));
   };

   setSearchTerm(searchTerm) {
      this.store.update({ searchTerm });
   }

   getPageParams() {
      const dependencies = [];

      dependencies.push(pageSize$);
      dependencies.push(this.query.select('searchTerm').pipe(debounceTime(800)));
      dependencies.push(uiQuery.select('showDeletedUsers'));

      let urlParams;

      if (dependencies.length) {
         return combineQueries(dependencies).pipe(
            filterNilValue(),
            distinctUntilChanged(),
            map((values) => {
               urlParams = new URLSearchParams();

               if (values.length === dependencies.length) {
                  const [limit, text, showDeleted] = values;

                  if (limit) urlParams.set('limit', limit);
                  if (showDeleted !== undefined) urlParams.set('deleted', showDeleted);
                  if (text) {
                     urlParams.set('text', text);
                     urlParams.set('organisationId', userContextQuery.getValue().organisation?.id);
                     urlParams.set('includeSubsidiaries', true);
                  }

                  return `?${urlParams.toString()}`;
               }

               return false;
            }),
            distinctUntilChanged(),
            tap((pageQueryParams) => {
               this.pageQueryParams = pageQueryParams;
               if (this.paginate && this.paginator.pages.size > 0) {
                  this.paginator.clearCache();
                  this.paginator.clearCache();
               }
            })
         );
      }

      urlParams = new URLSearchParams();

      return of(`?${urlParams.toString()}`);
   }

   async deleteCredential(credentialId) {
      const userId = userContextQuery.getValue().id;

      return this.httpClient
         .delete(`/${this.version}/users/${userId}/credentials/${credentialId}`)
         .then(() =>
            this.contextStore.update(({ publicKeys: publicKeys_ }) => ({
               publicKeys: arrayRemove(publicKeys_, (item) => item.credentialId === credentialId),
            }))
         )
         .catch((error) => this.setError(error, this.contextStore));
   }

   async restoreUser(userId) {
      return this.httpClient
         .post(`/${this.version}/${this.entityName}/${userId}/restore`)
         .then((resp) => this.store.update(userId, resp.data))
         .catch((error) => this.setError(error));
   }
}
