import { createEntityStore } from '@datorama/akita';

export const businessActivitiesStore = createEntityStore(
   {
      activeCriterion: undefined,
      statistics: undefined,
      filter: 'ALL',
      activeOrganisationAssignment: undefined,
      statusFilter: undefined,
      resultFilter: undefined,
      taxonomyActivityIdsFilter: undefined,
      searchTerm: undefined,
      assignedTaxonomyActivities: [],
      unassignedBusinessActivities: [],
      sort: {},
   },
   { name: 'taxonomy/businessactivities' }
);
export const responsesStore = createEntityStore([], { name: 'taxonomy/responses' });
