import { context$, periodContextQuery } from 'context/PeriodContext/query';
import { loading$ } from 'context/UserContext/query';
import UsersService from 'state/User/service';

const facade = {
  api: new UsersService(),
  query: periodContextQuery,
  context$,
  loading$,
};

export default facade;
