import { createQuery, filterNilValue } from '@datorama/akita';
import { distinctUntilChanged } from 'rxjs';
import userContextStore from 'context/UserContext/store';

export const userContextQuery = createQuery(userContextStore);

export const context$ = userContextQuery.select();

export const notifications$ = userContextQuery.select('notifications');
export const organisations$ = userContextQuery.select('organisations');
export const reportingStandards$ = userContextQuery.select('reportingStandards');
export const reportingPeriods$ = userContextQuery.select('periods');

export const pageSize$ = userContextQuery.select('pageSize').pipe(filterNilValue(), distinctUntilChanged());
export const locale$ = userContextQuery.select('locale');
export const includeSubsidiaries$ = userContextQuery.select('includeSubsidiaries');
export const loading$ = userContextQuery.selectLoading();
