import { combineQueries, createEntityQuery, Order } from '@datorama/akita';
import { map } from 'rxjs';
import { query as UIQuery } from 'state/UI/query';
import { nest } from 'utils/index';
import store from './store';

export const query = createEntityQuery(store, {
   sortBy: 'id',
   sortByOrder: Order.ASC,
});

export const entities$ = combineQueries([query.selectAll(), UIQuery.select('searchOrganizationList')]).pipe(
   map(([entities, searchTerm]) =>
      entities
         .filter(
            ({ name, code }) =>
               !searchTerm ||
               (name ?? '').toLowerCase().includes((searchTerm ?? '').toLowerCase()) ||
               (code ?? '').includes((searchTerm ?? '').toLowerCase())
         )
         .map((organisation) => ({
            ...organisation,
            label: organisation.code ? `(${organisation.code}) ${organisation.name}` : organisation.name,
            value: organisation.id,
         }))
   )
);
export const activeEntity$ = query.selectActive();

export const entityTree$ = query.selectAll().pipe(
   map((organisations) =>
      nest(
         organisations.map((organisation) => ({
            ...organisation,
            label: organisation.code ? `(${organisation.code}) ${organisation.name}` : organisation.name,
         }))
      ).sort((a, b) => a.children.length - b.children.length)
   )
);

export const statistics$ = query.select('statistics');
export const loading$ = query.selectLoading();

export const entitiesBeingDeleted$ = query.selectAll({
   filterBy: (entity) => !!entity?.deleteOperationStatus,
});
