import { combineQueries, createEntityQuery, Order } from '@datorama/akita';
import { context$ as periodContext$ } from 'context/PeriodContext/query';
import { formatISO } from 'date-fns';
import { map } from 'rxjs';
import store from './store';

const hasEveryScopeMateriality = (scopes, from, to, isMaterial) =>
   scopes.filter((kpiScope) => kpiScope.from === from && kpiScope.to === to).every((kpiScope) => !!kpiScope.isMaterial === isMaterial);

const hasEveryScopeMaterialityNested = (node, from, to, isMaterial) => {
   const childKeys = ['kpis', 'kpiSubs', 'kpiContents', 'kpiFields'];

   if (childKeys.some((childKey) => childKey in node)) {
      const childKey = childKeys.find((childKey_) => childKey_ in node);

      return (
         hasEveryScopeMateriality(node.kpiScopes, from, to, isMaterial) &&
         node[childKey].every((childNode) => hasEveryScopeMaterialityNested(childNode, from, to, isMaterial))
      );
   }

   return hasEveryScopeMateriality(node.kpiScopes, from, to, isMaterial);
};

const hasAnyChildScope = (node, from, to) => {
   const childKeys = ['kpis', 'kpiSubs', 'kpiContents', 'kpiFields'];

   if (childKeys.some((childKey) => childKey in node)) {
      const childKey = childKeys.find((childKey_) => childKey_ in node);

      return (
         (Array.isArray(node?.kpiScopes) ? node.kpiScopes.length > 0 : false) ||
         node[childKey].some((childNode) => hasAnyChildScope(childNode, from, to))
      );
   }

   return Array.isArray(node?.kpiScopes) ? node.kpiScopes.length > 0 : false;
};

const hasAnyScope = (from, to, nodes = []) => {
   return nodes.some((node) => hasAnyChildScope(node, from, to));
};

const calculateScope = (from, to, currentScope = null, nodes = []) => {
   if (!hasAnyScope(from, to, nodes)) return currentScope;

   if (nodes.every((node) => hasEveryScopeMaterialityNested(node, from, to, true))) return true;
   if (nodes.every((node) => hasEveryScopeMaterialityNested(node, from, to, false))) return false;
   return null;
};

export const query = createEntityQuery(store, {
   sortBy: 'position',
   sortByOrder: Order.ASC,
});

export const entities$ = query.selectAll();

const scopedEntities$ = combineQueries([query.selectAll(), periodContext$]).pipe(
   map(([kpiSet, periodContext]) =>
      Array.isArray(kpiSet) && periodContext
         ? kpiSet.map((kpiArea) => ({
              ...kpiArea,
              type: 'kpiArea',
              scope: calculateScope(
                 periodContext?.from,
                 periodContext?.to,
                 kpiArea.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)?.isMaterial,
                 kpiArea.kpis
              ),
              kpis: kpiArea.kpis.map((kpi) => ({
                 ...kpi,
                 type: 'kpi',
                 scope: calculateScope(
                    periodContext?.from,
                    periodContext?.to,
                    kpi.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)?.isMaterial,
                    kpi.kpiSubs
                 ),
                 kpiSubs: kpi.kpiSubs.map((kpiSub) => ({
                    ...kpiSub,
                    type: 'kpiSub',
                    scope: calculateScope(
                       periodContext?.from,
                       periodContext?.to,
                       kpiSub.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)?.isMaterial,
                       kpiSub.kpiContents
                    ),
                    kpiContents: kpiSub.kpiContents.map((kpiContent) => ({
                       ...kpiContent,
                       type: 'kpiContent',
                       scope: calculateScope(
                          periodContext?.from,
                          periodContext?.to,
                          kpiContent.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)
                             ?.isMaterial,
                          kpiContent.kpiFields
                       ),
                       kpiFields: kpiContent.kpiFields.map((kpiField) => ({
                          ...kpiField,
                          type: 'kpiField',
                          scope: calculateScope(
                             periodContext?.from,
                             periodContext?.to,
                             kpiField.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)
                                ?.isMaterial
                          ),
                       })),
                    })),
                 })),
              })),
           }))
         : []
   )
);

export const scopedEntitiesResult$ = combineQueries([query.selectAll(), periodContext$]).pipe(
   map(([kpiSet, periodContext]) =>
      Array.isArray(kpiSet) && periodContext
         ? kpiSet.map((kpiArea) => ({
              ...kpiArea,
              type: 'kpiArea',
              scope: calculateScope(
                 periodContext?.from,
                 periodContext?.to,
                 kpiArea.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)?.isMaterial,
                 kpiArea.kpis
              ),
              kpis: kpiArea.kpis.map((kpi) => ({
                 ...kpi,
                 type: 'kpi',
                 scope: calculateScope(
                    periodContext?.from,
                    periodContext?.to,
                    kpi.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)?.isMaterial ??
                       kpiArea.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)?.isMaterial,
                    kpi.kpiSubs
                 ),
                 kpiSubs: kpi.kpiSubs.map((kpiSub) => ({
                    ...kpiSub,
                    type: 'kpiSub',
                    scope: calculateScope(
                       periodContext?.from,
                       periodContext?.to,
                       kpiSub.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)?.isMaterial ??
                          kpi.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)?.isMaterial ??
                          kpiArea.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)
                             ?.isMaterial,
                       kpiSub.kpiContents
                    ),
                    kpiContents: kpiSub.kpiContents.map((kpiContent) => ({
                       ...kpiContent,
                       type: 'kpiContent',
                       scope: calculateScope(
                          periodContext?.from,
                          periodContext?.to,
                          kpiContent.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)
                             ?.isMaterial ??
                             kpiSub.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)
                                ?.isMaterial ??
                             kpi.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)
                                ?.isMaterial ??
                             kpiArea.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)
                                ?.isMaterial,
                          kpiContent.kpiFields
                       ),
                       kpiFields: kpiContent.kpiFields.map((kpiField) => ({
                          ...kpiField,
                          type: 'kpiField',
                          scope: calculateScope(
                             periodContext?.from,
                             periodContext?.to,
                             kpiField.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)
                                ?.isMaterial ??
                                kpiContent.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)
                                   ?.isMaterial ??
                                kpiSub.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)
                                   ?.isMaterial ??
                                kpi.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)
                                   ?.isMaterial ??
                                kpiArea.kpiScopes.find((kpiScope) => kpiScope.from === periodContext?.from && kpiScope.to === periodContext?.to)
                                   ?.isMaterial
                          ),
                       })),
                    })),
                 })),
              })),
           }))
         : []
   )
);

export const flatScopedEntitiesResult$ = scopedEntitiesResult$.pipe(
   map((kpiAreas) => [
      ...kpiAreas,
      ...kpiAreas.flatMap((kpiArea) => kpiArea.kpis),
      ...kpiAreas.flatMap((kpiArea) => kpiArea.kpis.flatMap((kpi) => kpi.kpiSubs)),
      ...kpiAreas.flatMap((kpiArea) => kpiArea.kpis.flatMap((kpi) => kpi.kpiSubs.flatMap((kpiSub) => kpiSub.kpiContents))),
      ...kpiAreas.flatMap((kpiArea) =>
         kpiArea.kpis.flatMap((kpi) => kpi.kpiSubs.flatMap((kpiSub) => kpiSub.kpiContents.flatMap((kpiContent) => kpiContent.kpiFields)))
      ),
   ])
);

export const flatEntitiesInScope$ = scopedEntities$.pipe(
   map((kpiAreas) => [
      ...kpiAreas.filter((kpiArea) => kpiArea.scope === true),
      ...kpiAreas.filter((kpiArea) => kpiArea.scope === null).flatMap((kpiArea) => kpiArea.kpis.filter((kpi) => kpi.scope === true)),
      ...kpiAreas
         .filter((kpiArea) => kpiArea.scope === null)
         .flatMap((kpiArea) =>
            kpiArea.kpis.filter((kpi) => kpi.scope === null).flatMap((kpi) => kpi.kpiSubs.filter((kpiSub) => kpiSub.scope === true))
         ),
      ...kpiAreas
         .filter((kpiArea) => kpiArea.scope === null)
         .flatMap((kpiArea) =>
            kpiArea.kpis
               .filter((kpi) => kpi.scope === null)
               .flatMap((kpi) =>
                  kpi.kpiSubs
                     .filter((kpiSub) => kpiSub.scope === null)
                     .flatMap((kpiSub) => kpiSub.kpiContents.filter((kpiContent) => kpiContent.scope === true))
               )
         ),
      ...kpiAreas
         .filter((kpiArea) => kpiArea.scope === null)
         .flatMap((kpiArea) =>
            kpiArea.kpis
               .filter((kpi) => kpi.scope === null)
               .flatMap((kpi) =>
                  kpi.kpiSubs
                     .filter((kpiSub) => kpiSub.scope === null)
                     .flatMap((kpiSub) =>
                        kpiSub.kpiContents
                           .filter((kpiContent) => kpiContent.scope === null)
                           .flatMap((kpiContent) => kpiContent.kpiFields.filter((kpiField) => kpiField.scope === true))
                     )
               )
         ),
   ])
);

export const flatEntitiesNotInScope$ = scopedEntities$.pipe(
   map((kpiAreas) => [
      ...kpiAreas.filter((kpiArea) => kpiArea.scope === false),
      ...kpiAreas.filter((kpiArea) => kpiArea.scope === null).flatMap((kpiArea) => kpiArea.kpis.filter((kpi) => kpi.scope === false)),
      ...kpiAreas
         .filter((kpiArea) => kpiArea.scope === null)
         .flatMap((kpiArea) =>
            kpiArea.kpis.filter((kpi) => kpi.scope === null).flatMap((kpi) => kpi.kpiSubs.filter((kpiSub) => kpiSub.scope === false))
         ),
      ...kpiAreas
         .filter((kpiArea) => kpiArea.scope === null)
         .flatMap((kpiArea) =>
            kpiArea.kpis
               .filter((kpi) => kpi.scope === null)
               .flatMap((kpi) =>
                  kpi.kpiSubs
                     .filter((kpiSub) => kpiSub.scope === null)
                     .flatMap((kpiSub) => kpiSub.kpiContents.filter((kpiContent) => kpiContent.scope === false))
               )
         ),
      ...kpiAreas
         .filter((kpiArea) => kpiArea.scope === null)
         .flatMap((kpiArea) =>
            kpiArea.kpis
               .filter((kpi) => kpi.scope === null)
               .flatMap((kpi) =>
                  kpi.kpiSubs
                     .filter((kpiSub) => kpiSub.scope === null)
                     .flatMap((kpiSub) =>
                        kpiSub.kpiContents
                           .filter((kpiContent) => kpiContent.scope === null)
                           .flatMap((kpiContent) => kpiContent.kpiFields.filter((kpiField) => kpiField.scope === false))
                     )
               )
         ),
   ])
);

export const activeNode$ = combineQueries([scopedEntitiesResult$, query.select('activeNode')]).pipe(
   map(([kpiSet, activeNode]) => {
      let match;

      if (Array.isArray(kpiSet) && activeNode) {
         if (kpiSet.find((kpiArea) => activeNode?.type === 'kpiArea' && kpiArea.id === activeNode?.id))
            return kpiSet.find((kpiArea) => activeNode?.type === 'kpiArea' && kpiArea.id === activeNode?.id);

         if (!match)
            kpiSet.forEach((kpiArea) => {
               if (kpiArea.kpis.find((kpi) => activeNode?.type === 'kpi' && kpi.id === activeNode?.id))
                  match = kpiArea.kpis.find((kpi) => activeNode?.type === 'kpi' && kpi.id === activeNode?.id);

               if (!match)
                  kpiArea.kpis.forEach((kpi) => {
                     if (kpi.kpiSubs.find((kpiSub) => activeNode?.type === 'kpiSub' && kpiSub.id === activeNode?.id))
                        match = kpi.kpiSubs.find((kpiSub) => activeNode?.type === 'kpiSub' && kpiSub.id === activeNode?.id);

                     if (!match)
                        kpi.kpiSubs.forEach((kpiSub) => {
                           if (kpiSub.kpiContents.find((kpiContent) => activeNode?.type === 'kpiContent' && kpiContent.id === activeNode?.id))
                              match = kpiSub.kpiContents.find((kpiContent) => activeNode?.type === 'kpiContent' && kpiContent.id === activeNode?.id);

                           if (!match)
                              kpiSub.kpiContents.forEach((kpiContent) => {
                                 if (kpiContent.kpiFields.find((kpiField) => activeNode?.type === 'kpiField' && kpiField.id === activeNode?.id))
                                    match = kpiContent.kpiFields.find(
                                       (kpiField) => activeNode?.type === 'kpiField' && kpiField.id === activeNode?.id
                                    );
                              });
                        });
                  });
            });
      }

      return match;
   })
);

const someScopesMatch = (nodes, isMaterial, from, to) =>
   nodes.every((node) =>
      node.kpiScopes.some(
         (kpiScope) =>
            kpiScope.from === from &&
            kpiScope.to === to &&
            kpiScope.reason &&
            kpiScope.isMaterial === isMaterial &&
            Number.isInteger(kpiScope.businessImpact) &&
            Number.isInteger(kpiScope.stakeholderImpact)
      )
   ) || nodes.length === 0;

export const allInScopeAnswered$ = combineQueries([flatEntitiesInScope$, periodContext$]).pipe(
   map(([nodes, periodContext]) =>
      Array.isArray(nodes) && periodContext
         ? someScopesMatch(
              nodes.filter(({ reportingStandard }) => reportingStandard?.key === 'GRI'),
              true,
              periodContext?.from,
              periodContext?.to
           )
         : false
   )
);

export const allOutOfScopeAnswered$ = combineQueries([flatEntitiesNotInScope$, periodContext$]).pipe(
   map(([nodes, periodContext]) =>
      Array.isArray(nodes) && periodContext
         ? someScopesMatch(
              nodes.filter(({ reportingStandard }) => reportingStandard?.key === 'GRI'),
              false,
              periodContext?.from,
              periodContext?.to
           )
         : false
   )
);

export const statistics$ = query.select('statistics');

export const kpiScopes$ = combineQueries([query.selectAll(), periodContext$]).pipe(
   map(([kpiAreas, periodContext]) => [
      ...kpiAreas.flatMap((kpiArea) =>
         kpiArea.kpiScopes.length === 0
            ? [
                 {
                    id: -1,
                    createdAt: formatISO(new Date()),
                    updateAt: formatISO(new Date()),
                    reason: '',
                    businessImpact: undefined,
                    stakeholderImpact: undefined,
                    isMaterial: true,
                    from: periodContext?.from,
                    to: periodContext?.to,
                    kind: 'kpiArea',
                    objectId: kpiArea.id,
                 },
              ]
            : kpiArea.kpiScopes.map((kpiScope) => ({ ...kpiScope, kind: 'kpiArea', objectId: kpiArea.id }))
      ),
      ...kpiAreas.flatMap((kpiArea) =>
         kpiArea.kpis.flatMap((kpi) =>
            kpi.kpiScopes.length === 0
               ? [
                    {
                       id: -1,
                       createdAt: formatISO(new Date()),
                       updateAt: formatISO(new Date()),
                       reason: '',
                       businessImpact: undefined,
                       stakeholderImpact: undefined,
                       isMaterial: true,
                       from: periodContext?.from,
                       to: periodContext?.to,
                       kind: 'kpi',
                       objectId: kpi.id,
                    },
                 ]
               : kpi.kpiScopes.map((kpiScope) => ({ ...kpiScope, kind: 'kpi', objectId: kpi.id }))
         )
      ),
      ...kpiAreas.flatMap((kpiArea) =>
         kpiArea.kpis.flatMap((kpi) =>
            kpi.kpiSubs.flatMap((kpiSub) =>
               kpiSub.kpiScopes.length === 0
                  ? [
                       {
                          id: -1,
                          createdAt: formatISO(new Date()),
                          updateAt: formatISO(new Date()),
                          reason: '',
                          businessImpact: undefined,
                          stakeholderImpact: undefined,
                          isMaterial: true,
                          from: periodContext?.from,
                          to: periodContext?.to,
                          kind: 'kpiSub',
                          objectId: kpiSub.id,
                       },
                    ]
                  : kpiSub.kpiScopes.map((kpiScope) => ({ ...kpiScope, kind: 'kpiSub', objectId: kpiSub.id }))
            )
         )
      ),
      ...kpiAreas.flatMap((kpiArea) =>
         kpiArea.kpis.flatMap((kpi) =>
            kpi.kpiSubs.flatMap((kpiSub) =>
               kpiSub.kpiContents.flatMap((kpiContent) =>
                  kpiContent.kpiScopes.length === 0
                     ? [
                          {
                             id: -1,
                             createdAt: formatISO(new Date()),
                             updateAt: formatISO(new Date()),
                             reason: '',
                             isMaterial: true,
                             businessImpact: undefined,
                             stakeholderImpact: undefined,
                             from: periodContext?.from,
                             to: periodContext?.to,
                             kind: 'kpiContent',
                             objectId: kpiContent.id,
                          },
                       ]
                     : kpiContent.kpiScopes.map((kpiScope) => ({ ...kpiScope, kind: 'kpiContent', objectId: kpiContent.id }))
               )
            )
         )
      ),
      ...kpiAreas.flatMap((kpiArea) =>
         kpiArea.kpis.flatMap((kpi) =>
            kpi.kpiSubs.flatMap((kpiSub) =>
               kpiSub.kpiContents.flatMap((kpiContent) =>
                  kpiContent.kpiFields.flatMap((kpiField) =>
                     kpiField.kpiScopes.length === 0
                        ? [
                             {
                                id: -1,
                                createdAt: formatISO(new Date()),
                                updateAt: formatISO(new Date()),
                                reason: '',
                                isMaterial: true,
                                businessImpact: undefined,
                                stakeholderImpact: undefined,
                                from: periodContext?.from,
                                to: periodContext?.to,
                                kind: 'kpiField',
                                objectId: kpiField.id,
                             },
                          ]
                        : kpiField.kpiScopes.map((kpiScope) => ({ ...kpiScope, kind: 'kpiField', objectId: kpiField.id }))
                  )
               )
            )
         )
      ),
   ])
);

export const loading$ = query.selectLoading();
