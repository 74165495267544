import googleAnalytics from '@analytics/google-analytics';
import { akitaConfig, enableAkitaProdMode } from '@datorama/akita';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far as farpro } from '@fortawesome/pro-regular-svg-icons';
import { fas as faspro } from '@fortawesome/pro-solid-svg-icons';
import { Loader } from '@googlemaps/js-api-loader';
import { Analytics } from 'analytics';
import doNotTrack, { doNotTrackEnabled } from 'analytics-plugin-do-not-track';
import App from 'components/App/App';
import config from 'config/config.default.json';
import { UserContextComponent } from 'context/UserContext';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-grid-layout/css/styles.css';
import { HelmetProvider } from 'react-helmet-async';
import 'react-resizable/css/styles.css';
import ApplicationService from 'state/Application/service';
import { AnalyticsProvider } from 'use-analytics';
import axiosClient from 'utils/axiosBaseClient';
import 'utils/globals.scss';
import { onCLS, onFID, onLCP } from 'web-vitals';
import packageInfo from '../package.json';

const applicationService = new ApplicationService();

axiosClient
   .get(`/auth/secrets`)
   .then(({ data }) => {
      const { googleMapsClientAPIKey: apiKey, 'recaptcha-siteKey': sitekey, NODE_ENV, CUSTOMER } = data;

      if (NODE_ENV) {
         applicationService.setProperty('NODE_ENV', NODE_ENV);
      }

      if (CUSTOMER) {
         applicationService.setProperty('CUSTOMER', CUSTOMER);
      }

      if (sitekey) {
         applicationService.setProperty('siteKey', sitekey);
         const script = document.createElement('script');
         script.type = 'text/javascript';
         script.async = true;
         script.src = `https://www.google.com/recaptcha/enterprise.js?render=${sitekey}`;
         document.head.appendChild(script);

         setTimeout(() => applicationService.setLoading(false), 2000);
      }

      if (apiKey) {
         applicationService.setProperty('googleMapsAPIKey', apiKey);

         try {
            const googleMapsAPILoader = new Loader({
               apiKey,
               version: 'weekly',
               libraries: ['places'],
            });

            googleMapsAPILoader.importLibrary('places');
         } catch (error) {
            return applicationService.setError(error);
         }
      }

      return true;
   })
   .catch((error) => applicationService.setError(error));

const dontTrack = doNotTrackEnabled();

const analytics = Analytics({
   app: packageInfo?.name ?? 'envoria',
   plugins: [
      doNotTrack(),
      googleAnalytics({
         measurementIds: config.google.tagManager.measurementIds,
         gtagConfig: {
            anonymize_ip: true,
         },
         enabled: !dontTrack && !['test', 'local'].includes(process.env.NODE_ENV),
         debug: process.env.NODE_ENV !== 'production',
      }),
   ],
   version: process.env.npm_package_version || packageInfo?.version,
   debug: process.env.NODE_ENV !== 'production',
});

function sendToGoogleAnalytics({ name, delta, value, id }) {
   // Assumes the global `gtag()` function exists, see:
   // https://developers.google.com/analytics/devguides/collection/ga4
   analytics.track(name, {
      // Built-in params:
      value: delta, // Use `delta` so the value can be summed.
      // Custom params:
      metric_id: id, // Needed to aggregate events.
      metric_value: value, // Optional.
      metric_delta: delta, // Optional.

      // OPTIONAL: any additional params or debug info here.
      // See: https://web.dev/debug-performance-in-the-field/
      // metric_rating: 'good' | 'needs-improvement' | 'poor',
      // debug_info: '...',
      // ...
   });
}

onCLS(sendToGoogleAnalytics);
onFID(sendToGoogleAnalytics);
onLCP(sendToGoogleAnalytics);

library.add(fas, far, faspro, farpro, fab);

const loadDevTools = async () => {
   const { akitaDevtools } = await import('@datorama/akita');
   akitaDevtools();
};

akitaConfig({ resettable: true });

if (process.env.NODE_ENV === 'production') enableAkitaProdMode();
else loadDevTools();

const container = document.getElementById('app');

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
   <React.StrictMode>
      <HelmetProvider>
         <UserContextComponent>
            <AnalyticsProvider instance={analytics}>
               <App />
            </AnalyticsProvider>
         </UserContextComponent>
      </HelmetProvider>
   </React.StrictMode>
);
