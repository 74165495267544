import { combineQueries, createEntityQuery, Order } from '@datorama/akita';
import { isEqual } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs';
import { activeEntityId$ } from 'state/KPI/query';
import { flatScopedEntitiesResult$ } from 'state/KPISet/query';
import store from './store';

const theQuery = createEntityQuery(store, {
  sortBy: 'position',
  sortByOrder: Order.ASC,
});

theQuery.createUIQuery();

export const query = theQuery;
export const uiquery = theQuery.ui;

export const allEntities$ = query.selectAll();

export const entities$ = combineQueries([allEntities$, activeEntityId$, flatScopedEntitiesResult$]).pipe(
  map(([kpiSubs, activeKPIId, scopingResults]) =>
    activeKPIId
      ? kpiSubs
          .filter((kpiSub) => kpiSub?.kpi?.id === activeKPIId)
          .map((kpiSub) => ({
            ...scopingResults.find((result) => result.type === 'kpiSub' && result.id === kpiSub.id),
            ...kpiSub,
            ui: uiquery.getEntity(kpiSub.id),
          }))
      : []
  ),
  distinctUntilChanged(isEqual)
);

export const activeEntity$ = combineQueries([theQuery.selectActive(), flatScopedEntitiesResult$]).pipe(
  map(([activeKPISub, scopingResults]) => ({
    ...scopingResults.find((result) => result.type === 'kpiSub' && result.id === activeKPISub?.id),
    ...activeKPISub,
  }))
);

export const loading$ = query.selectLoading();
