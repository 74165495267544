import { applyTransaction } from '@datorama/akita';
import { omit, pick } from 'lodash-es';
import { tap } from 'rxjs';
import CRUDService from 'state/CRUDService';
import kpiFieldsStore from 'state/KPIField/store';
import kpiValuesStore from 'state/KPIValue/store';
import { KPIFieldType } from 'utils/enum';
import { query, uiquery } from './query';
import store from './store';

export default class KPISubsService extends CRUDService {
  constructor() {
    super('kpisubs', store, query, [], true, true, undefined, false, true);
    this.entityObservables = {};
    this.kpiFieldsStore = kpiFieldsStore;
    this.kpiValuesStore = kpiValuesStore;
  }

  // This function is added here because in CRUD file we omit 'reportingStandards'.
  // Just until we clarify does 'reportingStandards' could be removed from omit func in main CRUD file.
  async persistEntityState(entity_, relevantKeys = []) {
    let entity;

    entity = omit(entity_, ['createdAt', 'updatedAt']);

    if (relevantKeys.length > 0) entity = pick(entity, relevantKeys);

    if (entity?.id <= 0) {
      return;
    }
    applyTransaction(() =>
      this.httpClient
        .patch(`/${this.version}/${this.entityName}/${entity.id}`, entity)
        .then((resp) => this.store.update(entity.id, resp.data))
        .catch((error) => {
          this.setError(error);
        })
    );
  }

  updateUIState(entityId, property, value) {
    if (!this.store.ui) throw Error(`Entity UI Store is not defined for store ${this.store}`);

    if (uiquery.getEntity(entityId, property)?.[property] !== value) this.store.ui.update(entityId, { [property]: value });
  }

  async #getFieldSumsInternal(entityId) {
    this.kpiValuesStore.setLoading(true);

    this.httpClient
      .get(`/${this.version}/${this.entityName}/${entityId}/fields/sums${this.queryParams ?? ''}`)
      .then((resp) =>
        applyTransaction(() => {
          this.kpiFieldsStore.upsertMany(
            resp.data.map((kpiValueInfo) => ({
              id: kpiValueInfo?.kpiFieldId,
              ...pick(kpiValueInfo, [
                'attachments',
                'status',
                'hasValueItems',
                'hasVersions',
                'isEstimated',
                'approver',
                'reviewer',
                'kpiValuesCount',
                'metas',
                'currency',
                'kpiValuesSumPreviousPeriod',
              ]),
            })),
            { loading: false }
          );

          this.kpiFieldsStore.upsertMany(
            resp.data
              .filter((kpiValueInfo) => kpiValueInfo?.kpiValuesCount !== 1 || kpiValueInfo?.resolvedFormulaArray)
              .map((kpiValueInfo) => ({
                id: kpiValueInfo?.kpiFieldId,
                ...omit(kpiValueInfo, ['kpiFieldId', 'kpiValueId']),
              })),
            { loading: false }
          );

          this.kpiValuesStore.remove((entity) =>
            resp.data
              .filter((kpiValueInfo) => kpiValueInfo?.kpiValuesCount !== 1 && !Number.isInteger(kpiValueInfo?.kpiValueId))
              .map((kpiValueInfo) => kpiValueInfo?.kpiFieldId)
              .includes(entity.kpiFieldId)
          );

          this.kpiValuesStore.upsertMany(
            resp.data
              .filter(
                (kpiValueInfo) =>
                  Number.isInteger(kpiValueInfo?.kpiValueId) &&
                  kpiValueInfo?.kpiValuesCount === 1 &&
                  kpiValueInfo.kpiFieldType.name !== KPIFieldType.TABLE
              )
              .map((kpiValueInfo) => ({
                ...omit(kpiValueInfo, [
                  'attachments',
                  'kpiValueId',
                  'kpiValuesCount',
                  'kpiValuesSum',
                  'kpiValuesSumCurrent',
                  'kpiValues',
                  'metas',
                  'kpiValuesSumPreviousPeriod',
                ]),
                id: kpiValueInfo?.kpiValueId,
              })),
            { loading: false }
          );

          this.kpiValuesStore.upsertMany(
            resp.data
              .filter(
                (kpiValueInfo) =>
                  kpiValueInfo.kpiFieldType.name === KPIFieldType.TABLE && Array.isArray(kpiValueInfo.kpiValues) && kpiValueInfo.kpiValues.length > 0
              )
              .flatMap((kpiValueInfo) =>
                kpiValueInfo.kpiValues.map((kpiValue) => ({
                  ...kpiValue,
                  kpiFieldId: kpiValueInfo?.kpiFieldId,
                }))
              ),
            { loading: false }
          );

          this.kpiValuesStore.setLoading(false);
        })
      )
      .catch((error) => {
        this.setError(error);
      });
  }

  async getFieldSums(entityId) {
    if (!this.entityObservables?.[entityId]) {
      this.entityObservables[entityId] = this.queryParamsObservable
        .pipe(
          tap((queryString) => {
            if (typeof queryString === 'string') return this.#getFieldSumsInternal(entityId);
            return undefined;
          })
        )
        .subscribe();
      return undefined;
    }
    return this.#getFieldSumsInternal(entityId);
  }
}
