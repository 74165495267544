import { applyTransaction, arrayAdd, arrayRemove } from '@datorama/akita';
import { organisationContextQuery } from 'context/OrganisationContext/query';
import { parseISO } from 'date-fns';
import { tap } from 'rxjs';
import { responsesStore } from 'state/BusinessActivities/store';
import CRUDService from 'state/CRUDService';
import { TaxonomyContributionType } from 'utils/enum';
import { query, responsesQuery } from './query';
import store from './store';

export default class MinimumSafeguardsService extends CRUDService {
   constructor() {
      if (!MinimumSafeguardsService.instance) {
         super('taxonomy/minimumsafeguards', store, query, [], true, true);

         this.responsesStore = responsesStore;
         this.organisationContextQuery = organisationContextQuery;

         MinimumSafeguardsService.instance = this;
      }

      // Service shall be instantiated only once, because otherwise the observable will be created for each service instance
      // eslint-disable-next-line no-constructor-return
      return MinimumSafeguardsService.instance;
   }

   getEntities(force = false, theStore = this.store) {
      super.getEntities(force, theStore);

      return this.queryParamsObservable
         .pipe(
            tap((queryString) => {
               if (typeof queryString === 'string') {
                  this.httpClient
                     .get(`/${this.version}/${this.entityName}/responses${queryString}`)
                     .then((resp) => this.responsesStore.upsertMany(resp.data))
                     .catch((error) => {
                        this.setError(error, responsesStore);
                     });
               }
            })
         )
         .subscribe();
   }

   getStatus() {
      return this.queryParamsObservable
         .pipe(
            tap((queryString) => {
               if (typeof queryString === 'string') {
                  this.httpClient
                     .get(`/${this.version}/${this.entityName}/status${queryString}`)
                     .then((resp) => this.store.update({ status: resp.data }))
                     .catch((error) => {
                        this.setError(error, responsesStore);
                     });
               }
            })
         )
         .subscribe();
   }

   async createCriterionResponse(criterionResponse) {
      return this.httpClient
         .post(`/${this.version}/taxonomy/criteriaresponses`, criterionResponse)
         .then((resp) => {
            this.responsesStore.add(resp.data);
            this.getStatus();
            return resp.data.id;
         })
         .catch((error) => {
            this.setError(error, responsesStore);
         });
   }

   async deleteCriterionResponse(criterionResponseIdOrArray) {
      let promise;

      if (Array.isArray(criterionResponseIdOrArray)) {
         promise = this.httpClient.delete(`/${this.version}/taxonomy/criteriaresponses`, { data: criterionResponseIdOrArray });
      } else promise = this.httpClient.delete(`/${this.version}/taxonomy/criteriaresponses/${criterionResponseIdOrArray}`);

      return promise
         .then(() => {
            this.getStatus();
            return this.responsesStore.remove(criterionResponseIdOrArray);
         })
         .catch((error) => {
            this.setError(error, responsesStore);
         });
   }

   async clearResponses(organisationId, from, to) {
      const responses = responsesQuery
         .getAll()
         .filter(
            (entity) =>
               entity.criterion.contributionType.code === TaxonomyContributionType.MINIMUM_SAFEGUARDS &&
               entity.organisation.id === organisationId &&
               parseISO(entity.validFrom) >= parseISO(from) &&
               parseISO(entity.validTo) <= parseISO(to)
         );

      return this.deleteCriterionResponse(responses.map((resp) => resp.id));
   }

   async persistCriteriaResponseChanges(changes, refresh = true) {
      this.responsesStore.setLoading(true);

      applyTransaction(() =>
         Promise.all(
            changes.map(({ id: responseId, ...change }) => this.httpClient.patch(`/${this.version}/taxonomy/criteriaresponses/${responseId}`, change))
         )
            .then((responses) => {
               this.responsesStore.upsertMany(responses.map((resp) => resp.data));
               if (refresh) this.getStatus();
               return this.responsesStore.setLoading(false);
            })
            .catch((err) => {
               this.responsesStore.setLoading(false);
               return this.setError(err, this.responsesStore);
            })
      );
   }

   hasExistingResponses(organisationId, from, to) {
      return this.responsesQuery.hasEntity(
         (entity) =>
            entity.organisation.id === organisationId &&
            entity.criterion.contributionType.code === TaxonomyContributionType.MINIMUM_SAFEGUARDS &&
            parseISO(entity.validFrom) >= parseISO(from) &&
            parseISO(entity.validTo) <= parseISO(to)
      );
   }

   async uploadResponseAttachments(responseId, files) {
      applyTransaction(() => {
         files.forEach((file) => {
            const formData = new FormData();
            formData.append('attachment', file);

            this.httpClient
               .post(`/${this.version}/taxonomy/criteriaresponses/${responseId}/attachments`, formData, { 'Content-Type': 'multipart/form-data' })
               .then((resp) => this.responsesStore.update(responseId, ({ attachments }) => ({ attachments: arrayAdd(attachments, resp.data) })))
               .catch((error) => {
                  this.setError(error);
               });
         });
      });
   }

   async deleteResponseAttachment(responseId, responseAttachmentId) {
      this.httpClient
         .delete(`/${this.version}/taxonomy/criteriaresponses/${responseId}/attachments/${responseAttachmentId}`)
         .then(() => this.responsesStore.update(responseId, ({ attachments }) => ({ attachments: arrayRemove(attachments, responseAttachmentId) })))
         .catch((error) => {
            this.setError(error);
         });
   }
}
