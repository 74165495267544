import { context$, organisationContextQuery, subsidiaryIds$ } from 'context/OrganisationContext/query';
import { loading$ } from 'context/UserContext/query';
import UsersService from 'state/User/service';

const api = new UsersService();

const facade = {
  api,
  query: organisationContextQuery,
  context$,
  loading$,
  subsidiaryIds$,
};

export default facade;
