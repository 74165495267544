import { applyTransaction, combineQueries, filterNilValue } from '@datorama/akita';
import { context$ } from 'context/OrganisationContext/query';
import { includeSubsidiaries$ } from 'context/UserContext/query';
import { formatISO9075 } from 'date-fns';
import { omit } from 'lodash-es';
import { distinctUntilChanged, map, of } from 'rxjs';
import CRUDService from 'state/CRUDService';
import kpiStore from 'state/KPI/store';
import kpiAreaStore from 'state/KPIArea/store';
import kpiContentStore from 'state/KPIContent/store';
import kpiFieldStore from 'state/KPIField/store';
import kpiSubStore from 'state/KPISub/store';
import { activeEntity$ as activeUIPeriod$ } from 'state/UIPeriod/query';
import { entities$, query } from './query';
import store from './store';

export default class KPISetService extends CRUDService {
   constructor() {
      if (!KPISetService.instance) {
         super('kpiset', store, query, [], true, true, undefined, true, true);

         this.statisticsQueryParamsObservable = this.getStatisticsQueryParams();

         entities$.subscribe((kpiSet) => {
            if (this.getLoading() === false) {
               kpiAreaStore.setLoading(true);
               kpiStore.setLoading(true);
               kpiSubStore.setLoading(true);
               kpiContentStore.setLoading(true);
               kpiFieldStore.setLoading(true);

               const kpiFieldIds = kpiSet.flatMap((kpiArea) =>
                  kpiArea.kpis.flatMap((kpi) =>
                     kpi.kpiSubs.flatMap((kpiSub) => kpiSub.kpiContents.flatMap((kpiContent) => kpiContent.kpiFields.map((kpiField) => kpiField.id)))
                  )
               );

               const kpiContentIds = kpiSet.flatMap((kpiArea) =>
                  kpiArea.kpis.flatMap((kpi) => kpi.kpiSubs.flatMap((kpiSub) => kpiSub.kpiContents.map((kpiContent) => kpiContent.id)))
               );

               const kpiSubIds = kpiSet.flatMap((kpiArea) => kpiArea.kpis.flatMap((kpi) => kpi.kpiSubs.flatMap((kpiSub) => kpiSub.id)));

               const kpiIds = kpiSet.flatMap((kpiArea) => kpiArea.kpis.flatMap((kpi) => kpi.id));

               const kpiAreaIds = kpiSet.map((kpiArea) => kpiArea.id);

               return applyTransaction(() => {
                  kpiFieldStore.remove(({ id }) => !kpiFieldIds.includes(id));
                  kpiContentStore.remove(({ id }) => !kpiContentIds.includes(id));
                  kpiSubStore.remove(({ id }) => !kpiSubIds.includes(id));
                  kpiStore.remove(({ id }) => !kpiIds.includes(id));
                  kpiAreaStore.remove(({ id }) => !kpiAreaIds.includes(id));

                  kpiAreaStore.upsertMany(kpiSet.map((kpiArea) => omit(kpiArea, ['kpis'])));

                  kpiSet.forEach((kpiArea) => {
                     kpiStore.upsertMany(
                        kpiArea.kpis.map((kpi) => omit({ ...kpi, kpiArea: { ...kpi.kpiArea, id: kpiArea.id, slug: kpiArea.slug } }, ['kpiSubs']))
                     );

                     kpiArea.kpis.forEach((kpi) => {
                        kpiSubStore.upsertMany(
                           kpi.kpiSubs.map((kpiSub) =>
                              omit({ ...kpiSub, kpi: { id: kpi.id, slug: kpi.slug, kpiArea: { id: kpiArea.id, slug: kpiArea.slug } } }, [
                                 'kpiContents',
                              ])
                           )
                        );

                        kpi.kpiSubs.forEach((kpiSub) => {
                           kpiContentStore.upsertMany(
                              kpiSub.kpiContents.map((kpiContent) =>
                                 omit({ ...kpiContent, kpiSub: { id: kpiSub.id, slug: kpiSub.slug } }, ['kpiFields'])
                              )
                           );

                           kpiSub.kpiContents.forEach((kpiContent) => {
                              kpiFieldStore.upsertMany(
                                 kpiContent.kpiFields.map((kpiField) => ({
                                    ...kpiField,
                                    kpiContent: {
                                       id: kpiContent.id,
                                       slug: kpiContent.slug,
                                       kpiSub: {
                                          id: kpiSub.id,
                                          slug: kpiSub.slug,
                                          kpi: {
                                             id: kpi.id,
                                             slug: kpi.slug,
                                             kpiArea: {
                                                id: kpiArea.id,
                                                slug: kpiArea.slug,
                                             },
                                          },
                                       },
                                    },
                                 }))
                              );
                           });
                        });
                     });
                  });

                  kpiAreaStore.setLoading(false);
                  kpiStore.setLoading(false);
                  kpiSubStore.setLoading(false);
                  kpiContentStore.setLoading(false);
                  kpiFieldStore.setLoading(false);
               });
            }
            return undefined;
         });

         KPISetService.instance = this;
      }

      // Service shall be instantiated only once, because otherwise the observable will be created for each service instance
      // eslint-disable-next-line no-constructor-return
      return KPISetService.instance;
   }

   async import(file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
         const result = await this.httpClient.post(`/${this.version}/${this.entityName}/import/json`, formData, {
            'Content-Type': 'multipart/form-data',
         });

         return result.data;
      } catch (err) {
         throw new Error(err?.response?.data?.message ?? err?.message ?? err);
      }
   }

   async export(reportingStandardId, kpiAreas = []) {
      const searchParams = new URLSearchParams();

      if (reportingStandardId) searchParams.set('reportingStandardId', reportingStandardId);
      if (kpiAreas?.length > 0) searchParams.set('kpiAreaIds', kpiAreas.join());

      return this.httpClient
         .get(`/${this.version}/${this.entityName}/export/json?${searchParams}`)
         .then((resp) => resp)
         .catch((error) => {
            this.setError(error);
         });
   }

   async exportValues(
      reportingStandardIds,
      from,
      to,
      includeSubsidiaryValues,
      kpiAreas = [],
      kpis = [],
      onlyApproved = false,
      reportGroupByOrganisation = false,
      emptyFields = false
   ) {
      const searchParams = new URLSearchParams();

      if (reportingStandardIds) searchParams.set('reportingStandardIds', reportingStandardIds.join(','));
      if (kpiAreas?.length > 0) searchParams.set('kpiAreaIds', kpiAreas.join());
      if (kpis?.length > 0) searchParams.set('kpis', kpis.join());
      searchParams.set('from', from);
      searchParams.set('to', to);
      searchParams.set('aggregationLevel', reportGroupByOrganisation ? 'entity' : 'group');
      searchParams.set('includeSubsidiaries', includeSubsidiaryValues ?? false);
      searchParams.set('onlyApproved', onlyApproved);
      searchParams.set('emptyFields', emptyFields);

      return this.httpClient
         .get(`/${this.version}/${this.entityName}/export/excel?${searchParams}`, { responseType: 'blob' })
         .then((resp) => resp)
         .catch((error) => {
            this.setError(error);
         });
   }

   getStatisticsQueryParams() {
      const dependencies = [];

      if (this.useOrganisationContext) dependencies.push(context$);
      dependencies.push(activeUIPeriod$);
      dependencies.push(includeSubsidiaries$);

      if (dependencies.length) {
         return combineQueries(dependencies).pipe(
            filterNilValue(),
            distinctUntilChanged(),
            map((values) => {
               const urlParamsSubClass = new URLSearchParams();

               if (this.useScope === true) urlParamsSubClass.set('scope', true);
               else if (this.useScope === false) urlParamsSubClass.set('scope', false);
               else urlParamsSubClass.set('scope', 'any');

               if (values.length === dependencies.length) {
                  const uiPeriod = values.find(
                     (value) => value && Object.prototype.hasOwnProperty.call(value, 'from') && Object.prototype.hasOwnProperty.call(value, 'to')
                  );
                  const includeSubsidiaries = values.find((value) => typeof value === 'boolean');
                  const organisation = values.find((value) => value && Object.prototype.hasOwnProperty.call(value, 'consolidationMethod'));

                  if (organisation?.id) urlParamsSubClass.set('organisationId', organisation?.id);

                  if (uiPeriod) {
                     urlParamsSubClass.set('from', formatISO9075(uiPeriod.from, { representation: 'date' }));
                     urlParamsSubClass.set('to', formatISO9075(uiPeriod.to, { representation: 'date' }));
                  }

                  if (includeSubsidiaries) urlParamsSubClass.set('includeSubsidiaries', includeSubsidiaries);

                  if (uiPeriod && organisation) return `?${urlParamsSubClass.toString()}`;
               }

               return false;
            })
         );
      }

      const urlParams = new URLSearchParams();

      if (this.useScope === true) urlParams.set('scope', true);
      else if (this.useScope === false) urlParams.set('scope', false);
      else urlParams.set('scope', 'any');
      return of(`?${urlParams.toString()}`);
   }

   async getStatistics(force = false) {
      if (force) {
         this.getStatisticsObservable.unsubscribe();
         this.getStatisticsObservable = undefined;
      }

      if (this.getStatisticsObservable === undefined)
         this.getStatisticsObservable = this.statisticsQueryParamsObservable
            .pipe(filterNilValue(), distinctUntilChanged())
            .subscribe((queryString) => {
               if (typeof queryString === 'string') {
                  this.store.setLoading(true);
                  this.httpClient
                     .get(`/${this.version}/statistics/kpisinfo${queryString}`)
                     .then((resp) => {
                        this.store.update({ statistics: resp.data });
                        return this.store.setLoading(false);
                     })
                     .catch((error) => {
                        this.setError(error);
                     });
               }

               return undefined;
            });
   }

   setActiveNode(type = null, id = null) {
      if (!type || !id) {
         this.store.update({ activeNode: undefined });
      } else {
         this.store.update({ activeNode: { type, id } });
      }
   }
}
