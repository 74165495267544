/* eslint-disable import/namespace */
import { getEntityStoreByName, getStoreByName } from '@datorama/akita';
import { shouldPolyfill } from '@formatjs/intl-datetimeformat/should-polyfill';
import { Button, CssBaseline, StyledEngineProvider, useMediaQuery } from '@mui/material';
import * as muiLocales from '@mui/material/locale';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as muiDataGridLocales from '@mui/x-data-grid/';
import axios from 'axios';
import ability from 'casl/ability';
import { AbilityContext } from 'casl/can';
import ApplicationContextComponent from 'context/ApplicationContext';
import LocaleContextComponent from 'context/LocaleContext';
import { OrganisationContextComponent } from 'context/OrganisationContext';
import { PeriodContextComponent } from 'context/PeriodContext';
import useUserContext from 'context/UserContext/hook';
import { flatten } from 'flat';
import { getUserLocale } from 'get-user-locale';
import { merge } from 'lodash-es';
import { SnackbarProvider } from 'notistack';
import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import generateRoutes from 'routes';
import useDesignFacade from 'state/Design/hook';
import envoriaTheme from 'themes/envoriaTheme';
import ThemedSnackbar from 'utils/ThemedSnackbar';

async function polyfillDateTimeFormat(locale) {
   const unsupportedLocale = shouldPolyfill(locale);
   // This locale is supported
   if (!unsupportedLocale) {
      return;
   }
   // Load the polyfill 1st BEFORE loading data
   await import('@formatjs/intl-datetimeformat/polyfill-force');

   // Parallelize CLDR data loading
   const dataPolyfills = [
      import('@formatjs/intl-datetimeformat/add-all-tz'),
      import(`@formatjs/intl-datetimeformat/locale-data/${unsupportedLocale}`),
   ];
   await Promise.all(dataPolyfills);
}

/*
async function polyfillGetCanonicalLocales() {
  // This platform already supports Intl.getCanonicalLocales
  if (shouldPolyfill()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill');
  }
}

async function polyfillIntlLocale() {
  // This platform already supports Intl.Locale
  if (shouldPolyfill()) {
    await import('@formatjs/intl-locale/polyfill');
  }
}
*/

async function polyfillDisplayNames(locale) {
   const unsupportedLocale = shouldPolyfill(locale);
   // This locale is supported
   if (!unsupportedLocale) {
      return;
   }
   // Load the polyfill 1st BEFORE loading data
   await import('@formatjs/intl-displaynames/polyfill-force');
   await import(`@formatjs/intl-displaynames/locale-data/${unsupportedLocale}`);
}

const defaultLocale = 'en-US';

let languageWithoutRegionCode = getUserLocale().substring(0, 2);

try {
   require.resolve(`lang/${languageWithoutRegionCode}.json`);
} catch (error) {
   languageWithoutRegionCode = defaultLocale.substring(0, 2);
}

axios.defaults.withCredentials = true;
axios.defaults.headers.common['Accept-Language'] = getUserLocale();

polyfillDateTimeFormat(languageWithoutRegionCode);
polyfillDisplayNames(languageWithoutRegionCode);

function App() {
   const { context: userContext } = useUserContext();
   const { design } = useDesignFacade();

   const notistackRef = createRef();
   const [messages, setMessages] = useState({});
   const [locale, setLocale] = useState(defaultLocale);

   useEffect(() => {
      async function loadMessages(localeOrLanguageCode) {
         let tempMessages = {};
         let returnLanguage;

         try {
            require.resolve(`lang/${localeOrLanguageCode.substring(0, 2)}.json`);
            tempMessages = await import(`lang/${localeOrLanguageCode.substring(0, 2)}.json`);
            returnLanguage = localeOrLanguageCode;
         } catch {
            tempMessages = await import(`lang/${defaultLocale.substring(0, 2)}.json`);
            returnLanguage = defaultLocale;
         }

         const customMessages =
            userContext?.activeOrganisation?.customization?.messages?.[userContext?.lang ? locale.substring(0, 2) : languageWithoutRegionCode] ?? {};

         setMessages(merge(tempMessages, customMessages));
         setLocale(returnLanguage);

         return returnLanguage;
      }

      if (userContext?.locale) {
         loadMessages(userContext.locale)
            .then((lang_) => {
               axios.defaults.headers.common['Accept-Language'] = lang_;
               document.documentElement.lang = lang_;
               return undefined;
            })
            .catch(() => {
               // do nothing
            });
      } else {
         loadMessages(languageWithoutRegionCode)
            .then((lang_) => {
               axios.defaults.headers.common['Accept-Language'] = lang_;
               document.documentElement.lang = lang_;
               return undefined;
            })
            .catch(() => {
               // do nothing
            });
      }
   }, [locale, userContext?.activeOrganisation?.customization?.messages, userContext?.lang, userContext?.locale]);

   const onClickDismiss = useCallback(
      (key) => () => {
         if (notistackRef.current) notistackRef.current.closeSnackbar(key);

         if ((key ?? '').toString().includes('#')) {
            const storeName = key.toString().substring(0, key.toString().indexOf('#'));

            if (storeName) {
               const store = getEntityStoreByName(storeName) ?? getStoreByName(storeName);

               if (store) {
                  const currentError = store.getValue()?.error;
                  if (currentError?.key === key) store.setError(null);
               }
            }
         }
      },
      [notistackRef]
   );

   const snackbarAction = useCallback(
      (key) => (
         <Button onClick={onClickDismiss(key)} sx={{ textTransform: 'uppercase', color: 'currentColor' }}>
            <FormattedMessage id="snackbars.dismiss" defaultMessage="Dismiss" />
         </Button>
      ),
      [onClickDismiss]
   );

   const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
   const mode = prefersDarkMode ? 'dark' : 'light';

   const modeTheme = useMemo(
      () =>
         createTheme(
            envoriaTheme(mode, userContext?.activeOrganisation?.theme ?? design?.theme),
            userContext?.locale && locale && muiLocales?.[locale.replace('-', '')] ? muiLocales?.[locale.replace('-', '')] : muiLocales.enUS,
            userContext?.locale && locale && muiLocales?.[locale.replace('-', '')]
               ? muiDataGridLocales?.[locale.replace('-', '')]
               : muiDataGridLocales.enUS
         ),
      [mode, userContext?.activeOrganisation?.theme, userContext?.locale, design?.theme, locale]
   );

   const router = createBrowserRouter(generateRoutes());

   return (
      <>
         <Helmet defaultTitle="envoria">
            <title>{design?.title}</title>
         </Helmet>
         <LocaleContextComponent>
            <ApplicationContextComponent>
               <OrganisationContextComponent>
                  <PeriodContextComponent>
                     <IntlProvider
                        locale={userContext?.lang ? locale.substring(0, 2) : languageWithoutRegionCode}
                        messages={flatten(messages)}
                        defaultLocale={defaultLocale.substring(0, 2)}
                     >
                        <StyledEngineProvider injectFirst>
                           <ThemeProvider theme={modeTheme}>
                              <SnackbarProvider
                                 dense
                                 maxSnack={3}
                                 preventDuplicate
                                 ref={notistackRef}
                                 action={snackbarAction}
                                 autoHideDuration={5000}
                                 Components={{
                                    default: ThemedSnackbar,
                                    success: ThemedSnackbar,
                                    error: ThemedSnackbar,
                                    warning: ThemedSnackbar,
                                    info: ThemedSnackbar,
                                 }}
                              >
                                 <AbilityContext.Provider value={ability}>
                                    <CssBaseline />
                                    <RouterProvider router={router} />
                                 </AbilityContext.Provider>
                              </SnackbarProvider>
                           </ThemeProvider>
                        </StyledEngineProvider>
                     </IntlProvider>
                  </PeriodContextComponent>
               </OrganisationContextComponent>
            </ApplicationContextComponent>
         </LocaleContextComponent>
      </>
   );
}

export default App;
