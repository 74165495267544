import { combineQueries, createEntityQuery, Order } from '@datorama/akita';
import { context$ as organisationContext$ } from 'context/OrganisationContext/query';
import { context$ as periodContext$ } from 'context/PeriodContext/query';
import { parseISO } from 'date-fns';
import { isEqual, uniqWith } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs';
import { responsesStore } from 'state/BusinessActivities/store';
import { TaxonomyAlignmentType, TaxonomyContributionType } from 'utils/enum';
import store from './store';

const nestCriteria = (items, id = undefined) =>
   items
      .filter((item) => item?.parent?.id === id)
      .sort((a, b) => (a?.key && b?.key ? a.key.localeCompare(b.key) : a.id - b.id))
      .map((item) => ({ ...item, criteria: nestCriteria(items, item.id) }));

export const query = createEntityQuery(store, { sortBy: 'updatedAt', sortByOrder: Order.DESC });

export const responsesQuery = createEntityQuery(responsesStore);

export const relevantCriteria$ = combineQueries([query.selectAll(), organisationContext$]).pipe(
   map(([criteria, organisationContext]) =>
      criteria.filter(
         (criterion) =>
            Array.isArray(organisationContext?.characteristics) && organisationContext.characteristics.some((c) => c.value === criterion.context)
      )
   )
);

export const responsesByActivity$ = combineQueries([relevantCriteria$, responsesQuery.selectAll(), periodContext$, organisationContext$]).pipe(
   map(([allCriteria, responses, periodContext, organisationContext]) =>
      uniqWith(
         allCriteria.filter((criterion) => criterion?.activity?.id).map((criterion) => criterion.activity),
         isEqual
      )
         .sort((a, b) => (a?.key ?? '').localeCompare(b?.key ?? ''))
         .map((activity) => ({
            ...activity,
            contribution:
               // eslint-disable-next-line no-nested-ternary
               !(
                  Array.isArray(organisationContext?.characteristics) &&
                  organisationContext.characteristics.some((c) => c.value === 'CONTROVERSIAL_WEAPON_EXPOSURE')
               ) &&
               allCriteria
                  .filter((criterion) => criterion?.activity?.id === activity.id && criterion?.answerable)
                  .every((criterion) =>
                     responses.some(
                        (response) =>
                           response?.criterion?.id === criterion.id &&
                           response?.organisation?.id === organisationContext?.id &&
                           parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                           parseISO(response?.validTo) <= parseISO(periodContext?.to) &&
                           response?.type?.code === 'NO'
                     )
                  )
                  ? { type: { code: TaxonomyContributionType.MINIMUM_SAFEGUARDS } }
                  : (Array.isArray(organisationContext?.characteristics) &&
                         organisationContext.characteristics.some((c) => c.value === 'CONTROVERSIAL_WEAPON_EXPOSURE')) ||
                      allCriteria
                         .filter((criterion) => criterion?.activity?.id === activity.id && criterion?.answerable)
                         .some((criterion) =>
                            responses.some(
                               (response) =>
                                  response?.criterion?.id === criterion.id &&
                                  response?.organisation?.id === organisationContext?.id &&
                                  parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                                  parseISO(response?.validTo) <= parseISO(periodContext?.to) &&
                                  response?.type?.code === 'YES'
                            )
                         )
                    ? { type: { code: TaxonomyContributionType.NOT_QUALIFIED } }
                    : undefined,
            criteria: nestCriteria(
               allCriteria
                  .filter((criterion) => criterion?.activity?.id === activity.id)
                  .map((criterion) => ({
                     ...criterion,
                     response: responses.find(
                        (response) =>
                           response?.criterion?.id === criterion.id &&
                           response?.organisation?.id === organisationContext?.id &&
                           parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                           parseISO(response?.validTo) <= parseISO(periodContext?.to)
                     ),
                  }))
            ),
         }))
   ),
   distinctUntilChanged(isEqual)
);

export const someOpen$ = combineQueries([relevantCriteria$, responsesQuery.selectAll(), periodContext$, organisationContext$]).pipe(
   map(
      ([criteria, responses, periodContext, organisationContext]) =>
         criteria.filter((criterion) => criterion?.answerable && TaxonomyContributionType.MINIMUM_SAFEGUARDS === criterion?.contributionType?.code)
            .length ===
            criteria.filter(
               (criterion) =>
                  criterion?.answerable &&
                  TaxonomyContributionType.MINIMUM_SAFEGUARDS === criterion?.contributionType?.code &&
                  responses.some(
                     (response) =>
                        response?.criterion?.id === criterion?.id &&
                        response?.organisation?.id === organisationContext?.id &&
                        parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                        parseISO(response?.validTo) <= parseISO(periodContext?.to)
                  )
            ).length &&
         !criteria.some(
            (criterion) =>
               criterion?.answerable &&
               TaxonomyContributionType.MINIMUM_SAFEGUARDS === criterion?.contributionType?.code &&
               responses.some(
                  (response) =>
                     response?.criterion?.id === criterion?.id &&
                     response?.organisation?.id === organisationContext?.id &&
                     parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                     parseISO(response?.validTo) <= parseISO(periodContext?.to) &&
                     response?.type?.code === 'YES'
               )
         )
   ),
   distinctUntilChanged()
);

export const allResponses$ = combineQueries([relevantCriteria$, responsesQuery.selectAll(), organisationContext$, periodContext$]).pipe(
   map(([criteria, responses, organisationContext, periodContext]) =>
      responses.filter(
         (response) =>
            criteria
               .filter(({ answerable }) => answerable === true)
               .map((criterion) => criterion.id)
               .includes(response?.criterion?.id) &&
            response.organisation.id === organisationContext?.id &&
            response.validFrom === periodContext?.from &&
            response.validTo === periodContext?.to
      )
   )
);

export const alignmentResult$ = combineQueries([relevantCriteria$, responsesQuery.selectAll(), periodContext$, organisationContext$]).pipe(
   map(([criteria, responses, periodContext, organisationContext]) => {
      if (Array.isArray(organisationContext?.characteristics) && organisationContext.characteristics.length === 0) return undefined;
      if (
         !responses.some(
            (response) =>
               criteria
                  .filter(({ answerable }) => answerable === true)
                  .map((criterion) => criterion.id)
                  .includes(response?.criterion?.id) &&
               response?.organisation?.id === organisationContext?.id &&
               parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
               parseISO(response?.validTo) <= parseISO(periodContext?.to)
         ) &&
         !(
            Array.isArray(organisationContext?.characteristics) &&
            organisationContext.characteristics.some((c) => c.value === 'CONTROVERSIAL_WEAPON_EXPOSURE')
         )
      )
         return undefined;

      if (
         criteria.some((criterion) =>
            responses.find(
               (response) =>
                  response?.criterion?.id === criterion?.id &&
                  response?.organisation?.id === organisationContext?.id &&
                  parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                  parseISO(response?.validTo) <= parseISO(periodContext?.to) &&
                  response?.type?.code === 'YES'
            )
         ) ||
         (Array.isArray(organisationContext?.characteristics) &&
            organisationContext.characteristics.some((c) => c.value === 'CONTROVERSIAL_WEAPON_EXPOSURE'))
      )
         return TaxonomyAlignmentType.NOT_ALIGNED;

      if (
         criteria
            .filter(({ answerable }) => answerable === true)
            .every((criterion) =>
               responses.find(
                  (response) =>
                     response?.criterion?.id === criterion?.id &&
                     response?.organisation?.id === organisationContext?.id &&
                     parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                     parseISO(response?.validTo) <= parseISO(periodContext?.to) &&
                     response?.type?.code === 'NO'
               )
            )
      )
         return TaxonomyAlignmentType.ALIGNED;

      return undefined;
   })
);

export const status$ = query.select('status');
