import { subject } from '@casl/ability';
import { arrayMove } from '@dnd-kit/sortable';
import { Box, Link, MenuItem, Tooltip } from '@mui/material';
import ability from 'casl/ability';
import Icon from 'components/Icon';
import { Currencies } from 'currencies-map';
import { addDays, format, formatISO, getMonth, getQuarter, intervalToDuration, isDate, isSameQuarter } from 'date-fns';
import parse, { domToReact } from 'html-react-parser';
import Qty from 'js-quantities';
import { findLastIndex, get } from 'lodash-es';
import { evaluate, unit } from 'mathjs';
import { useCallback, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import regionCodeMapping from 'resources/json/RegionCodes.json';
import sanctionedUnits from 'resources/json/sanctionedUnits.json';
import {
   ApprovalStatus,
   EntityRelationType,
   ImportJobTypes,
   KPIFieldType,
   KPIValueType,
   OrganisationType,
   ReferencedArea,
   SearchResultKind,
   TaskCycle,
   TaskStatus,
   TaxonomyAlignmentType,
   TaxonomyContributionType,
} from 'utils/enum';
import ELITypeMapping from '../resources/json/ELITypeMapping.json';

const romanNumerals = ['i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix', 'x'];

const unitReplacement = {
   tonne: 't',
   'room-night': null,
   'CPU-hour': 'h',
   'instance-hour': 'h',
   'passenger-km': 'km',
   'passenger-mile': 'mile',
   'person-night': null,
   'gal (US)': 'gal',
   't-km': 'tkm',
   L: 'l',
   'container-moved': null,
};

function taxonomyReplaceFunction(domNode, lang = 'en') {
   const language = Object.keys(ELITypeMapping.general).includes(lang) ? lang : 'en';

   if (domNode.type === 'tag' && domNode.name === 'a') {
      return domNode.attribs?.href && domNode.attribs.href.startsWith('#') ? (
         <Link component={HashLink} to={domNode.attribs.href} smooth title={domNode.attribs.title}>
            {domToReact(domNode.children)}
         </Link>
      ) : (
         <Link href={domNode.attribs.href} title={domNode.attribs.title} rel="noreferrer" target="_blank">
            {domToReact(domNode.children)}
         </Link>
      );
   }
   if (domNode.type === 'text') {
      Object.entries(ELITypeMapping)
         .filter(([key]) => key !== 'general')
         .forEach(([eliDocType, langMapping]) => {
            // eslint-disable-next-line security/detect-non-literal-regexp
            const regExp = new RegExp(
               `(?<=${langMapping?.[language] ?? langMapping?.en ?? ''}${
                  ELITypeMapping.general?.[language] ?? ELITypeMapping.general?.en ?? ''
               })\\d{3,4}\\/\\d{2,4}(\\/\\w*)?`,
               'gim'
            );

            const matches = domNode.data.match(regExp);

            if (Array.isArray(matches)) {
               matches.forEach((match) => {
                  const year = match.match(/(?:19|20)\d{2}/g);

                  if (Array.isArray(year) && year.length > 0) {
                     const documentNo = match
                        .trim()
                        .replace('/', '')
                        .replace(year[0], '')
                        .replace(/\/\w*$/, '');

                     if (documentNo)
                        domNode.data = domNode.data.replace(
                           match,
                           ` <a target="_blank" rel="noreferrer" href="https://eur-lex.europa.eu/eli/${eliDocType}/${year[0]}/${documentNo}">${match}</a>`
                        );
                  }
               });
            }
         });

      return <span>{parse(domNode.data)}</span>;
   }

   return undefined;
}

function truncateNumber(numToTruncate, decimalPlaces) {
   // eslint-disable-next-line security/detect-non-literal-regexp
   const re = new RegExp(`(\\d+\\.\\d{${decimalPlaces}})(\\d)`);
   const m = numToTruncate.toString().match(re);
   return m ? parseFloat(m[1]) : numToTruncate.valueOf();
}

export const truncateText = (text = '', delimeter = 100) => text.substring(0, delimeter) + (text.length > delimeter ? '...' : '');

function getDecimalSeparator(locale) {
   const number = 1.1;

   return Intl.NumberFormat(locale)
      .formatToParts(number)
      .find((part) => part.type === 'decimal').value;
}

function getGroupingSeparator(locale) {
   const number = 1000;

   return Intl.NumberFormat(locale)
      .formatToParts(number)
      .find((part) => part.type === 'group').value;
}

async function handleDrag(active, over) {
   if (active?.data?.current?.type && Array.isArray(over?.data?.current?.accepts) && over.data.current.accepts.includes(active.data.current.type)) {
      let parentKey;
      let collectionName;
      let parentEntityName;

      switch (active.data.current.type) {
         case 'footprintSub':
            parentKey = 'footprintId';
            collectionName = 'subs';
            parentEntityName = 'emissions/ghg/footprints';
            break;
         case 'fpCalculation':
            parentKey = 'footprintSubId';
            collectionName = 'calculations';
            parentEntityName = 'emissions/ghg/footprintssubs';
            break;
         case 'fpCalculationField':
            parentKey = 'fpCalculationId';
            collectionName = 'fields';
            parentEntityName = 'emissions/ghg/calculations';
            break;
         case 'kpi':
            parentKey = 'kpiAreaId';
            collectionName = 'kpis';
            parentEntityName = 'kpiAreas';
            break;
         case 'kpiSub':
            parentKey = 'kpiId';
            collectionName = 'kpisubs';
            parentEntityName = 'kpis';
            break;
         case 'kpiContent':
            parentKey = 'kpiSubId';
            collectionName = 'kpicontents';
            parentEntityName = 'kpisubs';
            break;
         case 'kpiField':
            parentKey = 'kpiContentId';
            collectionName = 'kpifields';
            parentEntityName = 'kpicontents';
            break;
         default:
            break;
      }

      // rearrange in the same container
      if (active.id !== over.id && active.data.current.sortable.containerId === over.data.current.sortable.containerId) {
         const oldIndex = active.data.current.sortable.items.indexOf(active.id);
         const newIndex = over.data.current.sortable.items.indexOf(over.id);

         active.data.current.api.sortEntities(
            arrayMove(active.data.current.sortable.items, oldIndex, newIndex),
            parentEntityName,
            `${active.data.current.type}Id` === parentKey ? active.id : active.data.current.sortable.containerId,
            collectionName
         );
         // move to a different container
      } else if (active.data.current.sortable.containerId !== over.data.current.sortable.containerId) {
         await active.data.current.api.updateEntity(active.id, {
            [parentKey]: `${over.data.current.type}Id` === parentKey ? over.id : over.data.current.sortable.containerId,
         });
         over.data.current.api.sortEntities(
            over.data.current.sortable.items.splice(over.data.current.sortable.index, 0, active.id),
            parentEntityName,
            `${over.data.current.type}Id` === parentKey ? over.id : over.data.current.sortable.containerId,
            collectionName
         );
         active.data.current.api.sortEntities(
            active.data.current.sortable.items.splice(active.data.current.sortable.items.indexOf(active.id), 1),
            parentEntityName,
            `${active.data.current.type}Id` === parentKey ? active.id : active.data.current.sortable.containerId,
            collectionName
         );
      }
   }
}

function getEntityKindIcon(kind) {
   let icon;

   switch (kind) {
      case 'all':
         icon = 'search';
         break;
      case 'kpiarea':
      case 'kpi':
      case 'kpisub':
      case 'kpicontent':
      case 'kpifield':
         icon = 'pen-field';
         break;
      case 'businessactivity':
         icon = 'briefcase';
         break;
      case 'taxonomyactivity':
         icon = 'leaf';
         break;
      case 'emissioncategory':
         icon = 'smoke';
         break;
      case 'organisation':
         icon = 'sitemap';
         break;
      case 'chart':
         icon = 'chart-column';
         break;
      case 'report':
         icon = 'book';
         break;
      case 'reportdocument':
         icon = 'file-word';
         break;
      case 'reportingstandard':
         icon = 'file-certificate';
         break;
      case 'task':
         icon = 'list-check';
         break;
      case 'user':
         icon = 'user';
         break;
      case 'group':
         icon = 'user-group';
         break;
      case 'currency':
         icon = 'coins';
         break;
      case 'footprint':
         icon = 'shoe-prints';
         break;
      case 'footprintcalculation':
         icon = 'calculator';
         break;
      case 'footprintcalculationfield':
         icon = 'shoe-prints';
         break;
      case 'refArea':
         icon = 'bullseye';
         break;
      default:
         break;
   }

   return <Icon icon={icon} fontSize="small" />;
}

function getOrganisationIcon(organisationType) {
   let icon;

   switch (organisationType) {
      case OrganisationType.REGION:
         icon = 'globe';
         break;
      case OrganisationType.COMMUNE:
         icon = 'users';
         break;
      case OrganisationType.AREA:
         icon = 'layer-group';
         break;
      case OrganisationType.PRODUCT:
         icon = 'tube-chemistry';
         break;
      case OrganisationType.PORTFOLIO:
         icon = 'folder-open';
         break;
      case OrganisationType.INVESTMENT:
         icon = 'hand-holding-usd';
         break;
      case OrganisationType.ORGANIZATION:
      default:
         icon = 'building';
         break;
   }

   return <Icon icon={icon} />;
}

function getEntityRelationIcon(entityRelation) {
   let icon;

   switch (entityRelation) {
      case EntityRelationType.CUSTOMER:
         icon = 'truck-field';
         break;
      case EntityRelationType.SUPPLIER:
         icon = 'user-check';
         break;
      case EntityRelationType.SUBSIDIARY:
      default:
         icon = 'building';
         break;
   }

   return <Icon icon={icon} />;
}

function getReferencedAreaIcon(area) {
   let icon;

   switch (area) {
      case ReferencedArea.EMI:
         icon = 'shoe-prints';
         break;
      case ReferencedArea.EUT:
         icon = 'briefcase';
         break;
      case ReferencedArea.GEN:
         icon = 'bullseye';
         break;
      case ReferencedArea.KPI:
         icon = 'pen-field';
         break;
      case ReferencedArea.ORG:
         icon = 'building';
         break;
      case ReferencedArea.REP:
         icon = 'book';
         break;
      case ReferencedArea.SET:
         icon = 'cog';
         break;
      default:
         icon = 'bullseye';
         break;
   }

   return <Icon icon={icon} fontSize="small" />;
}

function localizeOptions(intl, optionsList) {
   return optionsList.map((option) => ({
      ...option,
      ...(option?.label
         ? {
              label: intl.formatMessage({
                 id: option.label.id,
                 defaultMessage: option.label.defaultMessage,
              }),
           }
         : {}),
   }));
}

function localizeLanguageOptions(optionsList) {
   return Object.entries(optionsList).map(([key]) => key);
}

function groupArrayByKey(list, keyGetter) {
   const map = new Map();
   list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
         map.set(key, [item]);
      } else {
         collection.push(item);
      }
   });
   return map;
}

function formatOrganisationName(organisation, formattedProportion) {
   if (!organisation.parentId) {
      return organisation.code ? `${organisation.code}: ${organisation.name}` : organisation.name;
   }

   let proportion = '';

   if (organisation.parentId) {
      proportion = `(${formattedProportion})`;
   }

   return organisation.code ? `${organisation.code}: ${organisation.name} ${proportion}` : `${organisation.name} ${proportion}`;
}

function capitalizeFirstLetter(string) {
   if (string) {
      const stringLC = string.toLowerCase();
      return stringLC.charAt(0).toUpperCase() + stringLC.slice(1);
   }
   return null;
}

function formatSelectedObject(selected, prop, value) {
   if (selected) {
      return {
         [prop]: value,
         value: selected.id,
         label: prop ? `${value} ${selected.name}` : selected.name,
      };
   }
   return null;
}

function taxonomySearchList(activities, mergedList) {
   let searchList = activities ? Array.from(activities) : [];
   if (mergedList?.length > 0) {
      mergedList.forEach((merged) => {
         searchList = activities?.filter((activity) => merged.id !== activity.id);
      });
   }
   // mergedList?.length > 0 && mergedList.forEach(merged => {
   // 	searchList = activities.filter(activity => merged.id !== activity.id)
   // })
   return searchList;
}

function groupBy(data, keyFn) {
   return data.reduce((all, current) => {
      const group = keyFn(current);
      const existingKey = all.find((existing) => existing.key === group);
      if (!existingKey) {
         all.push({ key: group, values: [current] });
      } else {
         existingKey.values.push(current);
      }
      return all;
   }, []);
}

function getContributionTypeColor(contributionTypeCode) {
   switch (contributionTypeCode) {
      case TaxonomyContributionType.SUBSTANTIAL_CONTRIBUTION:
      case TaxonomyContributionType.ENABLING_ACTIVITY:
      case TaxonomyContributionType.TRANSITIONAL_ACTIVITY:
      case TaxonomyContributionType.MINIMUM_SAFEGUARDS:
         return 'success.main';
      case TaxonomyContributionType.NOT_QUALIFIED:
         return 'error.main';
      case 'MIXED':
         return 'success.light';
      case TaxonomyContributionType.DO_NO_SIGNIFICANT_HARM:
      default:
         return 'grey[400]';
   }
}

function getAlignmentTypeColor(alignmentTypeCode) {
   switch (alignmentTypeCode) {
      case TaxonomyAlignmentType.ELIGIBLE:
         return 'info.main';
      case TaxonomyAlignmentType.ALIGNED:
         return 'success.main';
      case TaxonomyAlignmentType.PARTIALLY_ALIGNED:
         return 'info.main';
      case TaxonomyAlignmentType.NOT_ALIGNED:
         return 'error.main';
      case TaxonomyAlignmentType.NOT_ELIGIBLE:
      default:
         return 'grey[400]';
   }
}

function transformAllowedAuditorsAndEditorsToIds(object) {
   return {
      ...object,
      allowedAuditors: Array.isArray(object?.allowedAuditors) ? object.allowedAuditors.map((auditor) => auditor.id) : [],
      allowedEditors: Array.isArray(object?.allowedEditors) ? object.allowedEditors.map((editor) => editor.id) : [],
   };
}

function getAuditStatusColor(auditStatus) {
   switch (auditStatus) {
      case ApprovalStatus.REVIEWED:
         return 'info.main';
      case ApprovalStatus.APPROVED:
         return 'success.main';
      case ApprovalStatus.ARCHIVED:
         return 'text.disabled';
      case 'UNARCHIVE':
         return 'warning.main';
      case 'UNAPPROVE':
         return 'warning.main';
      case 'UNREVIEW':
         return 'warning.main';
      case ApprovalStatus.NO_VERIFICATION_NEEDED:
      case ApprovalStatus.IN_PROGRESS:
      default:
         return 'text.primary';
   }
}

function getAuditStatusIcon(auditStatus) {
   switch (auditStatus) {
      case ApprovalStatus.REVIEWED:
         return 'check_circle_outline';
      case ApprovalStatus.APPROVED:
         return 'check_circle';
      case ApprovalStatus.ARCHIVED:
         return 'inventory_2';
      case 'UNARCHIVE':
         return 'unarchive';
      case 'UNAPPROVE':
         return 'thumbs-down';
      case 'UNREVIEW':
         return 'thumbs-down';
      case 'REVIEW':
         return 'eye';
      case 'APPROVE':
         return 'thumbs-up';
      case 'ARCHIVE':
         return 'box-archive';
      case ApprovalStatus.NO_VERIFICATION_NEEDED:
      case ApprovalStatus.IN_PROGRESS:
      default:
         return 'hardware';
   }
}

function getImportJobTypeColor(importJobType) {
   switch (importJobType) {
      case ImportJobTypes.SUCCESS:
         return 'success.main';
      case ImportJobTypes.ERROR:
         return 'error.main';
      case ImportJobTypes.WARNING:
         return 'warning.main';
      case ImportJobTypes.DELETED:
         return 'grey[700]';
      case ImportJobTypes.PENDING:
      default:
         return 'grey[400]';
   }
}

function getTaskStatusColor(taskStatus) {
   switch (taskStatus) {
      case 'OPEN':
         return 'error.main';
      case 'DELETED':
         return 'blueGrey[400]';
      case 'HOLD':
         return 'warning.main';
      case 'REVIEW':
         return 'orange[600]';
      case 'PROGRESS':
         return 'info.main';
      case 'FINISHED':
         return 'success.main';
      default:
         return 'grey[700]';
   }
}

function createMenuItems(objects = [], key = 'id', value = 'value', label = 'label') {
   return objects.map((option) => (
      <MenuItem key={option[key]} value={option[value]}>
         {option[label]}
      </MenuItem>
   ));
}

function replaceKeysInObj(data, oldKey, newKey) {
   const replaceKey = (func) => (newData) => {
      if (Array.isArray(newData)) return newData.map(replaceKey(func));

      if (Object(newData) === newData) return Object.fromEntries(Object.entries(newData).map(([key, value]) => [func(key), replaceKey(func)(value)]));
      return newData;
   };
   return replaceKey((key) => (key === oldKey ? newKey : key))(data);
}

function useCenteredTree(layout) {
   const [translate, setTranslate] = useState({ x: 0, y: 0 });
   const [minHeight, setMinHeight] = useState(150);
   const [containerWidth, setContainerWidth] = useState(0);
   const [containerHeight, setContainerHeight] = useState(0);
   const [treeWidth, setTreeWidth] = useState(360);
   const [fitZoomFactor, setFitZoomFactor] = useState(0);
   const [itemDim, setItemDim] = useState({ w: 0, h: 0 });
   const [zoomFactor, setZoom] = useState(0);

   const updateZoomFactor = useCallback((newVal) => {
      setZoom(newVal);
   }, []);

   const containerRef = useCallback(
      (containerElem) => {
         if (containerElem !== null) {
            const { height, width } = containerElem.getBoundingClientRect();
            const { height: tHeight, width: tWidth, left: tOffset } = containerElem.querySelector('.rd3t-g').getBoundingClientRect();
            const childNodes = containerElem.querySelector('.rd3t-g > .rd3t-node');

            let innerOffset;
            if (childNodes) {
               innerOffset = childNodes.getBoundingClientRect().left;
            } else innerOffset = 0;

            const treeWidth = tWidth;
            const treeHeight = tHeight;
            const zoomScale = Math.min(1 / ((treeWidth + 100) / width), 1);
            const offset = innerOffset * zoomScale - tOffset * zoomScale + 180 * zoomScale;
            const adjustedTreeWidth = treeWidth * zoomScale;
            const adjustedTreeHeight = treeHeight * zoomScale;
            setFitZoomFactor(zoomScale);
            setItemDim({ w: tWidth, h: tHeight });
            setContainerWidth(width);
            setContainerHeight(height);
            setMinHeight(adjustedTreeHeight + 100);
            setTreeWidth(adjustedTreeWidth);
            setTranslate({ x: (width - adjustedTreeWidth) / layout + offset, y: (height - adjustedTreeHeight) / layout });
            updateZoomFactor(zoomScale);
         }
      },
      [layout, updateZoomFactor]
   );
   return {
      translate,
      containerRef,
      minHeight,
      fitZoomFactor,
      containerWidth,
      containerHeight,
      treeWidth,
      itemDim,
      zoomFactor,
      updateZoomFactor,
   };
}

function convertFormulaArrayToExpression(formulaArray) {
   return formulaArray
      .map((item) => (typeof item === 'string' ? item.trim() : item)) // clean spaces
      .reduce((formulaText, currentToken, currentIndex, formulaArray_) => {
         const processedPart = formulaArray_.slice(0, currentIndex - 1);

         const lastClosedFormulaIndex = processedPart.lastIndexOf(')');
         const lastOpenedFormulaIndex = findLastIndex(
            processedPart,
            (item) => (item?.type === 'FUNCTION' && item?.value.match(/\w{1,30}\(/g)) || item.toString().match(/\w{1,30}\(/g)
         );

         if (currentIndex > 0 && lastClosedFormulaIndex < lastOpenedFormulaIndex && currentToken.toString() !== ')') {
            return formulaText.concat(',', currentToken);
         }

         return formulaText.concat(currentToken);
      }, '');
}

function replaceExpressions(expression) {
   return Array.from(expression, (formulaItem) => {
      if (typeof formulaItem === 'object') {
         if (formulaItem?.type === 'FUNCTION') return formulaItem.value;
         return 1;
      }
      return formulaItem;
   });
}

function getFormulaExpressionErrorMessage(expression) {
   if (!expression) return undefined;

   const expressions = Array.from(expression);

   const replacedExpressions = replaceExpressions(expressions);

   const formulaString = convertFormulaArrayToExpression(replacedExpressions);

   try {
      evaluate(formulaString);
      return undefined;
   } catch (e) {
      return e?.message;
   }
}

function taskCycle(intl) {
   return [
      {
         label: intl.formatMessage({
            id: 'tasks.cycle-once',
            defaultMessage: 'One time',
         }),
         value: TaskCycle.ONCE,
      },
      {
         label: intl.formatMessage({
            id: 'tasks.cycle-days',
            defaultMessage: 'Daily',
         }),
         value: TaskCycle.DAILY,
      },
      {
         label: intl.formatMessage({
            id: 'tasks.cycle-weeks',
            defaultMessage: 'Weekly',
         }),
         value: TaskCycle.WEEKLY,
      },
      {
         label: intl.formatMessage({
            id: 'tasks.cycle-months',
            defaultMessage: 'Monthly',
         }),
         value: TaskCycle.MONTHLY,
      },
      {
         label: intl.formatMessage({
            id: 'tasks.cycle-quarters',
            defaultMessage: 'Quarterly',
         }),
         value: TaskCycle.QUARTERLY,
      },
      {
         label: intl.formatMessage({
            id: 'tasks.cycle-years',
            defaultMessage: 'Yearly',
         }),
         value: TaskCycle.YEARLY,
      },
   ];
}

function taskPrio(intl) {
   return [
      {
         label: intl.formatMessage({
            id: 'tasks.priority1',
            defaultMessage: 'Highest',
         }),
         value: 1,
         color: 'error.main',
      },
      {
         label: intl.formatMessage({
            id: 'tasks.priority2',
            defaultMessage: 'High',
         }),
         value: 2,
         color: 'orange[600]',
      },
      {
         label: intl.formatMessage({
            id: 'tasks.priority3',
            defaultMessage: 'Medium',
         }),
         value: 3,
         color: 'warning.main',
      },
      {
         label: intl.formatMessage({
            id: 'tasks.priority4',
            defaultMessage: 'Low',
         }),
         value: 4,
         color: 'success.main',
      },
      {
         label: intl.formatMessage({
            id: 'tasks.priority5',
            defaultMessage: 'Lowest',
         }),
         value: 5,
         color: 'blueGrey[400]',
      },
   ];
}

function tasksAction(intl) {
   return new Map([
      [
         TaskStatus.OPEN,
         [
            {
               label: intl.formatMessage({
                  id: 'tasks.action.hold',
                  defaultMessage: 'Set On hold',
               }),
               value: TaskStatus.HOLD,
               color: TaskStatus.HOLD,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.finalize',
                  defaultMessage: 'Finalize',
               }),
               value: TaskStatus.REVIEW,
               color: TaskStatus.FINISHED,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.delete',
                  defaultMessage: 'Delete',
               }),
               value: TaskStatus.DELETED,
               color: TaskStatus.OPEN,
            },
         ],
      ],
      [
         TaskStatus.PROGRESS,
         [
            {
               label: intl.formatMessage({
                  id: 'tasks.action.hold',
                  defaultMessage: 'Set On hold',
               }),
               value: TaskStatus.HOLD,
               color: TaskStatus.HOLD,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.finalize',
                  defaultMessage: 'Finalize',
               }),
               value: TaskStatus.REVIEW,
               color: TaskStatus.FINISHED,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.delete',
                  defaultMessage: 'Delete',
               }),
               value: TaskStatus.DELETED,
               color: TaskStatus.OPEN,
            },
         ],
      ],
      [
         TaskStatus.REVIEW,
         [
            {
               label: intl.formatMessage({
                  id: 'tasks.action.reOpen',
                  defaultMessage: 'Re-Open',
               }),
               value: TaskStatus.OPEN,
               color: TaskStatus.PROGRES,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.hold',
                  defaultMessage: 'Set On Hold',
               }),
               value: TaskStatus.HOLD,
               color: TaskStatus.HOLD,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.approve',
                  defaultMessage: 'Approve',
               }),
               value: TaskStatus.FINISHED,
               color: TaskStatus.FINISHED,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.delete',
                  defaultMessage: 'Delete',
               }),
               value: TaskStatus.DELETED,
               color: TaskStatus.OPEN,
            },
         ],
      ],
      [
         TaskStatus.HOLD,
         [
            {
               label: intl.formatMessage({
                  id: 'tasks.action.resume',
                  defaultMessage: 'Resume',
               }),
               value: TaskStatus.OPEN,
               color: TaskStatus.PROGRESS,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.delete',
                  defaultMessage: 'Delete',
               }),
               value: TaskStatus.DELETED,
               color: TaskStatus.OPEN,
            },
         ],
      ],
      [
         TaskStatus.FINISHED,
         [
            {
               label: intl.formatMessage({
                  id: 'tasks.action.reOpen',
                  defaultMessage: 'Re-Open',
               }),
               value: TaskStatus.OPEN,
               color: TaskStatus.PROGRESS,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.archive',
                  defaultMessage: 'Archive',
               }),
               value: TaskStatus.DELETED,
               color: TaskStatus.DELETED,
            },
         ],
      ],
      [TaskStatus.DELETED, []],
   ]);
}

function determineDefaultEntryPath() {
   if (!ability) return '403';
   if (ability.can('read', 'chart') && ability.can('read', subject('overviewTab', { userId: -1 }))) return 'dashboard/overview';
   if (ability.can('read', 'kpiArea')) return 'kpi';
   if (ability.can('read', subject('businessActivity', {}))) return 'taxonomy';
   if (ability.can('read', subject('footprint', {}))) return 'emissions/ghg';
   if (ability.can('read', 'report')) return 'reports';
   if (ability.can('read', 'task')) return 'tasks';
   return 'users/me';
}

function determineDashboardEntryPath() {
   if (!ability) return '403';
   if (ability.can('read', 'chart') && ability.can('read', subject('overviewTab', { userId: -1 }))) return 'dashboard/overview';
   if (ability.can('read', 'kpiArea')) return 'dashboard/overview';
   if (ability.can('read', subject('businessActivity', {}))) return 'taxonomy';
   if (ability.can('read', subject('footprint', {}))) return 'dashboard/status'; // TODO change if we have a dedicated dashboard
   return 'dashboard/status';
}

function nest(items, id = null, link = 'parentId') {
   return items.filter((item) => item[link] === id).map((item) => ({ ...item, children: nest(items, item.id) }));
}

function friendlyFormatDateTimeRange(from, to) {
   const getHalfYear = (date) => (getQuarter(date) <= 2 ? 1 : 2);

   const duration = intervalToDuration({ start: from, end: addDays(to, 1) });

   const [unevenPart, unevenValue] = Object.entries(duration).find(([, value]) => value !== 0);

   if (unevenPart === 'years' && unevenValue === 1) return format(to, 'yyyy');

   if (!['years', 'months'].includes(unevenPart)) return undefined;

   if (Object.values(duration).filter((value) => value === 0).length !== Object.values(duration).length - 1) return undefined;

   if (unevenPart === 'months')
      switch (unevenValue) {
         case 1:
            return format(to, 'MM yyyy');
         case 3:
            return isSameQuarter(from, to) ? format(to, 'QQQ yyyy') : undefined;
         case 6:
            return [0, 5].includes(getMonth(from)) ? `H${getHalfYear(from)} ${from.getFullYear()}` : undefined;
         default:
            return undefined;
      }

   return undefined;
}

function fieldTypeRequiresUnit(kpiFieldType) {
   return [
      KPIFieldType.WEIGHT,
      KPIFieldType.SURFACE,
      KPIFieldType.MEASUREMENT,
      KPIFieldType.EMISSIONS,
      KPIFieldType.EMISSION_SELECT,
      KPIFieldType.DISTANCE,
      KPIFieldType.ELECTRICITY,
      KPIFieldType.VOLUME,
      KPIFieldType.WOOD,
      KPIFieldType.TIME,
   ].includes(kpiFieldType);
}

function fieldTypeAllowsUnit(kpiFieldType) {
   return fieldTypeRequiresUnit(kpiFieldType) || kpiFieldType === KPIFieldType.FORMULA;
}

function fieldTypeIsNumeric(kpiFieldType) {
   return [
      KPIFieldType.WEIGHT,
      KPIFieldType.SURFACE,
      KPIFieldType.PRICE,
      KPIFieldType.MEASUREMENT,
      KPIFieldType.EMISSIONS,
      KPIFieldType.EMISSION_SELECT,
      KPIFieldType.DISTANCE,
      KPIFieldType.ELECTRICITY,
      KPIFieldType.VOLUME,
      KPIFieldType.PERCENTAGE,
      KPIFieldType.NUMBER,
      KPIFieldType.DATA,
      KPIFieldType.FORMULA,
      KPIFieldType.PASSENGER,
      KPIFieldType.WOOD,
      KPIFieldType.TIME,
   ].includes(kpiFieldType);
}

function getValueTypeForFieldType(kpiFieldType) {
   switch (kpiFieldType) {
      case KPIFieldType.SELECT:
         return KPIValueType.INTEGER;
      case KPIFieldType.SWITCH:
         return KPIValueType.BOOLEAN;
      case KPIFieldType.WEIGHT:
      case KPIFieldType.SURFACE:
      case KPIFieldType.PRICE:
      case KPIFieldType.MEASUREMENT:
      case KPIFieldType.EMISSIONS:
      case KPIFieldType.EMISSION_SELECT:
      case KPIFieldType.DISTANCE:
      case KPIFieldType.ELECTRICITY:
      case KPIFieldType.VOLUME:
      case KPIFieldType.PERCENTAGE:
      case KPIFieldType.NUMBER:
      case KPIFieldType.DATA:
      case KPIFieldType.FORMULA:
      case KPIFieldType.PASSENGER:
      case KPIFieldType.WOOD:
      case KPIFieldType.TIME:
         return KPIValueType.DECIMAL;
      case KPIFieldType.DATE:
         return KPIValueType.DATE;
      case KPIFieldType.TEXT:
      case KPIFieldType.TEXTAREA:
      default:
         return KPIValueType.TEXT;
   }
}

function canAccessOrganisation(organisationId) {
   return ability.can('read', subject('organisation', { organisationId }));
}

function determineListStyle(criteria) {
   const listStyle = {};

   if (criteria.every((criterion) => !criterion.key)) listStyle.listStyleType = 'none';
   if (criteria.some((criterion) => criterion.key && Number.isInteger(Number.parseInt(criterion.key, 10)))) listStyle.listStyleType = 'decimal';
   if (criteria.every((criterion) => criterion.key && romanNumerals.includes(criterion.key))) listStyle.listStyle = 'lower-roman-taxonomy';
   if (criteria.every((criterion) => criterion.key && /[a-z]/.test(criterion.key))) listStyle.listStyle = 'lower-latin-taxonomy';

   return listStyle;
}

function determineListIndex(key) {
   if (!key) return 0;

   const normalizedKey = key.endsWith('.') ? key.substring(0, key.length - 1) : key;

   if (normalizedKey.includes('.') && Number.isInteger(Number.parseInt(normalizedKey, 10)))
      return parseInt(normalizedKey.substring(normalizedKey.lastIndexOf('.') + 1), 10);

   if (Number.isInteger(Number.parseInt(normalizedKey, 10))) return parseInt(normalizedKey, 10);

   if (romanNumerals.includes(normalizedKey)) return romanNumerals.indexOf(normalizedKey) + 1;

   return normalizedKey.toLowerCase().charCodeAt(0) - 97 + 1;
}

function determineListItemStyle(key) {
   if (!key) return 'none';
   return 'inherit';
}

function getStatusColor(status) {
   switch (status) {
      case true:
      case 'APPROVED':
      case 'FINISHED':
      case 'CLOSED':
      case 'FILLED':
         return 'success.main';
      case false:
      case 'DELETED':
         return 'error.main';
      case 'ARCHIVED':
         return 'blueGrey[400]';
      case 'HOLD':
      case 'REVIEWED':
         return 'warning.main';
      case 'REVIEW':
         return 'orange[600]';
      case 'PROGRESS':
      case 'OPEN':
      case 'UNFILLED':
      case 'IN_PROGRESS':
      case 'NO_VERIFICATION_NEEDED':
         return 'info.main';
      default:
         return 'grey[400]';
   }
}

function getPercentageColor(value) {
   if (value <= 20) {
      return 'error.main';
   }
   if (value >= 21 && value <= 50) {
      return 'orange[600]';
   }
   if (value >= 51 && value <= 99) {
      return 'warning.main';
   }
   if (value >= 100) {
      return 'success.main';
   }
   return 'background.default';
}

function getActionStatusColor(status) {
   switch (status) {
      case 'LOW':
         return 'success.main';
      case 'MEDIUM':
      case 'MED':
         return 'warning.main';
      case 'HIGH':
         return 'orange[600]';
      case 'HIGHER':
         return 'error.main';
      default:
         return 'grey[700]';
   }
}

function getCurrencySymbol(intl, shortCode) {
   const parts = intl.formatNumberToParts(0, {
      style: 'currency',
      currency: shortCode,
      currencyDisplay: 'narrowSymbol',
      signDisplay: 'always',
   });
   return parts.find((part) => part.type === 'currency')?.value;
}

function convertDatesToUTC(obj) {
   return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, isDate(value) ? formatISO(value, { representation: 'date' }) : value]));
}

function determineBaseUnit(unit) {
   if (!unit) return unit;

   const [, baseUnit] = unit.includes('/') ? unit.split('/') : [];

   if (baseUnit && Currencies.names.has(baseUnit)) return undefined;

   if (baseUnit in unitReplacement) return baseUnit.replace(baseUnit, unitReplacement[baseUnit]);
   return baseUnit;
}

function formatRegionCode(intl, code) {
   let formattedCode;

   let [sanitizedCode] = code.split('-');
   [sanitizedCode] = sanitizedCode.split('_');

   if (sanitizedCode === 'UK') sanitizedCode = 'GB';

   // eslint-disable-next-line compat/compat
   const regularIntl = new Intl.DisplayNames([intl.locale], { type: 'region', style: 'long' });

   try {
      regularIntl.of(sanitizedCode);

      formattedCode = intl.formatDisplayName(sanitizedCode, {
         type: 'region',
         style: 'long',
      });
   } catch (err) {
      formattedCode = intl.formatMessage({
         id: `emissions.regions.${code}`,
         defaultMessage: regionCodeMapping?.[code] ?? code,
      });
   }

   return formattedCode;
}

function determineAuditStatusTooltipTitle(intl, object) {
   switch (object?.status) {
      case ApprovalStatus.APPROVED:
         return intl.formatMessage(
            {
               id: 'common.approvedBy',
               defaultMessage: 'Status: {status} {br} Approved by {approver} {br} Reviewed by {reviewer}',
            },
            {
               approver: `${object?.approver?.firstName} ${object?.approver?.lastName}`,
               reviewer: `${object?.reviewer?.firstName} ${object?.reviewer?.lastName}`,
               br: <br />,
               status: intl.formatMessage({
                  id: `overview.tabs.status.${object?.status}`,
                  defaultMessage: (object?.status ?? '').toLowerCase(),
               }),
            }
         );
      case ApprovalStatus.REVIEWED:
         return intl.formatMessage(
            {
               id: 'common.reviewedBy',
               defaultMessage: 'Status: {status} {br} Reviewed by {reviewer}',
            },
            {
               reviewer: `${object?.reviewer?.firstName} ${object?.reviewer?.lastName}`,
               br: <br />,
               status: intl.formatMessage({
                  id: `overview.tabs.status.${object?.status}`,
                  defaultMessage: (object?.status ?? '').toLowerCase(),
               }),
            }
         );
      default:
         return intl.formatMessage(
            {
               id: `overview.tabs.status.tooltip`,
               defaultMessage: 'Status: {status}',
            },
            {
               status: intl.formatMessage({
                  id: `overview.tabs.status.${object?.status}`,
                  defaultMessage: (object?.status ?? '').toLowerCase(),
               }),
            }
         );
   }
}

function getAuditStatusNode(intl, palette, object, fontSize = 20) {
   return (
      <Tooltip title={determineAuditStatusTooltipTitle(intl, object)}>
         <Box component="span" display="inline-flex">
            <Icon icon={getAuditStatusIcon(object?.status)} fontSize={fontSize} sx={{ color: get(palette, getAuditStatusColor(object?.status)) }} />
         </Box>
      </Tooltip>
   );
}

function getAuditActions(status) {
   switch (status) {
      case ApprovalStatus.IN_PROGRESS:
         return ['REVIEW', 'ARCHIVE'];
      case ApprovalStatus.REVIEWED:
         return ['APPROVE', 'UNREVIEW', 'ARCHIVE'];
      case ApprovalStatus.APPROVED:
         return ['UNAPPROVE', 'ARCHIVE'];
      case ApprovalStatus.ARCHIVED:
         return ['UNARCHIVE'];
      default:
         return [];
   }
}

function getAuditAllActions(entities) {
   if (entities.length < 1) return [];
   let status;
   const allInProgress = entities.every((entity) => entity.status === ApprovalStatus.IN_PROGRESS);
   const allReviewed = entities.every((entity) => entity.status === ApprovalStatus.REVIEWED);
   const allApproved = entities.every((entity) => entity.status === ApprovalStatus.APPROVED);
   const allArchived = entities.every((entity) => entity.status === ApprovalStatus.ARCHIVED);

   if (allInProgress) status = ApprovalStatus.IN_PROGRESS;
   else if (allReviewed) status = ApprovalStatus.REVIEWED;
   else if (allApproved) status = ApprovalStatus.APPROVED;
   else if (allArchived) status = ApprovalStatus.ARCHIVED;
   else status = undefined;
   return getAuditActions(status);
}

const replacementChars = ['/', '-'];

function sanitizeUnit(unit) {
   let returnUnit = unit ?? '';

   switch (returnUnit) {
      case 'ha':
         returnUnit = 'hectare';
         break;
      case 'a':
         returnUnit = 'acre';
         break;
      default:
   }

   returnUnit = returnUnit
      .replace('²', '^2')
      .replace('³', '^3')
      .replace(/\B(\d)/g, '^$1');

   replacementChars.forEach((char) => {
      if (returnUnit.includes(char))
         returnUnit = returnUnit
            .split(char)
            .map((aUnit) => {
               if (aUnit === 'ha') return 'hectare';
               if (aUnit === 'a') return 'acre';
               return aUnit;
            })
            .join(char);
   });

   return returnUnit;
}

function convertUnit(unitFrom, unitTo, amount) {
   const sourceUnit = sanitizeUnit(unitFrom);
   const targetUnit = sanitizeUnit(unitTo);

   if (sourceUnit === targetUnit) return amount;

   if ([sourceUnit, targetUnit].some((aUnit) => ['Gt', 'Mt'].includes(aUnit))) return unit(`${amount}${sourceUnit}`).toNumber(targetUnit);

   if ((!sourceUnit && targetUnit) || (targetUnit && !sourceUnit))
      throw new Error(JSON.stringify({ error: 'units_not_compatible', from: unitFrom ?? '', to: unitTo ?? '', amount }));

   if (!Qty.parse(sourceUnit)) {
      throw new Error(JSON.stringify({ error: 'unknown_unit', unit: unitFrom, amount }));
   }
   if (!Qty.parse(targetUnit)) {
      throw new Error(JSON.stringify({ error: 'unknown_unit', unit: targetUnit, amount }));
   }
   if (Qty.parse(sourceUnit) && Qty.parse(targetUnit) && !Qty.parse(sourceUnit).isCompatible(Qty.parse(targetUnit)))
      throw new Error(JSON.stringify({ error: 'units_not_compatible', from: unitFrom ?? '', to: unitTo ?? '', amount }));

   return Qty.parse(`${amount}${sourceUnit}`).to(targetUnit).scalar;
}

function formatEmissionResult(intl, result, emissionUnit, formatOptions = {}) {
   return `${intl.formatNumber(convertUnit('kg', emissionUnit, result), {
      useGrouping: true,
      maximumFractionDigits: 0,
      style: sanctionedUnits.some((unit) => Qty.getAliases(emissionUnit).includes(unit)) ? 'unit' : 'decimal',
      unit: sanctionedUnits.find((unit) => Qty.getAliases(emissionUnit).includes(unit)),
      ...formatOptions,
   })} ${sanctionedUnits.some((unit) => Qty.getAliases(emissionUnit).includes(unit)) ? ' ' : emissionUnit.concat(' ')}CO₂`;
}

function useReferencedObjectNavigation(navigate) {
   const navigateToRef = (referencedObject) => {
      const value = referencedObject;
      if (value?.kind) {
         switch (value.kind) {
            case SearchResultKind.KPI_AREA:
               return navigate(`/kpi/${value.slug}`);
            case SearchResultKind.KPI:
               return navigate(`/kpi/${value.kpiArea?.slug}/${value.slug}`);
            case SearchResultKind.KPI_SUB:
               return navigate(`/kpi/${value.kpi?.kpiArea?.slug}/${value.kpi?.slug}/${value.slug}`);
            case SearchResultKind.KPI_CONTENT:
               return navigate(`/kpi/${value.kpiSub?.kpi?.kpiArea?.slug}/${value.kpiSub?.kpi?.slug}/${value.kpiSub?.slug}/${value.slug}`);
            case SearchResultKind.KPI_FIELD:
               return navigate(
                  `/kpi/${value.kpiContent?.kpiSub?.kpi?.kpiArea?.slug}/${value.kpiContent?.kpiSub?.kpi?.slug}/${value.kpiContent?.kpiSub?.slug}/${value.kpiContent?.slug}/${value.slug}`
               );
            case SearchResultKind.BUSINESS_ACTIVITY:
               return navigate(`/taxonomy/businessactivities/${value.id}`);
            case SearchResultKind.FOOTPRINT:
               return navigate(`/emissions/ghg/footprints/${value.id}`);
            case SearchResultKind.REPORT:
               return navigate(`/reports/documents/${value.id}`);
            default:
               return undefined;
         }
      }
   };

   return {
      navigateToRef,
   };
}

export {
   canAccessOrganisation,
   capitalizeFirstLetter,
   convertDatesToUTC,
   convertFormulaArrayToExpression,
   convertUnit,
   createMenuItems,
   determineAuditStatusTooltipTitle,
   determineBaseUnit,
   determineDashboardEntryPath,
   determineDefaultEntryPath,
   determineListIndex,
   determineListItemStyle,
   determineListStyle,
   fieldTypeAllowsUnit,
   fieldTypeIsNumeric,
   fieldTypeRequiresUnit,
   formatEmissionResult,
   formatOrganisationName,
   formatRegionCode,
   formatSelectedObject,
   friendlyFormatDateTimeRange,
   getActionStatusColor,
   getAlignmentTypeColor,
   getAuditActions,
   getAuditAllActions,
   getAuditStatusColor,
   getAuditStatusIcon,
   getAuditStatusNode,
   getContributionTypeColor,
   getCurrencySymbol,
   getDecimalSeparator,
   getEntityKindIcon,
   getEntityRelationIcon,
   getFormulaExpressionErrorMessage,
   getGroupingSeparator,
   getImportJobTypeColor,
   getOrganisationIcon,
   getPercentageColor,
   getReferencedAreaIcon,
   getStatusColor,
   getTaskStatusColor,
   getValueTypeForFieldType,
   groupArrayByKey,
   groupBy,
   handleDrag,
   localizeLanguageOptions,
   localizeOptions,
   nest,
   replaceKeysInObj,
   taskCycle,
   taskPrio,
   tasksAction,
   taxonomyReplaceFunction,
   taxonomySearchList,
   transformAllowedAuditorsAndEditorsToIds,
   truncateNumber,
   useCenteredTree,
   useReferencedObjectNavigation
};

