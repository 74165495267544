import { Backdrop } from '@mui/material';
import PropTypes from 'prop-types';
import EnvoriaLoadingIcon from 'components/v2/EnvoriaLoadingIcon';

function FullPageLoadingIndicator({ open, sx, children, ...restOfProps }) {
  return (
    <Backdrop open={open} sx={{ zIndex: (theme) => theme.zIndex.modal + 1, ...sx }} {...restOfProps}>
      <EnvoriaLoadingIcon />
      {children}
    </Backdrop>
  );
}

FullPageLoadingIndicator.defaultProps = {
  open: true,
  sx: {},
  children: null,
};

FullPageLoadingIndicator.propTypes = {
  open: PropTypes.bool,
  sx: PropTypes.shape({}),
  children: PropTypes.node,
};

export default FullPageLoadingIndicator;
