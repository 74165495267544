import { combineQueries, createQuery, filterNilValue } from '@datorama/akita';
import userContextStore from 'context/UserContext/store';
import { flatMapDeep } from 'lodash-es';
import { map } from 'rxjs';
import { entities$ as organisations$ } from 'state/Organisations/query';

function getParentIds(organisation, previousParentIds, organisations) {
   if (organisation?.parentId) {
      return getParentIds(
         organisations.find(({ id }) => id === organisation.parentId),
         previousParentIds.concat(organisation.parentId),
         organisations
      );
   }
   return previousParentIds;
}

export const organisationContextQuery = createQuery(userContextStore);

export const context$ = organisationContextQuery.select('activeOrganisation');

function flatten(item) {
   return [item, flatMapDeep(item.subsidiaries, flatten)];
}

export const subsidiaryIds$ = context$.pipe(
   filterNilValue(),
   map((activeOrganisation) => flatMapDeep([activeOrganisation], flatten).map((org) => org.id))
);

export const parentIds$ = combineQueries([context$, organisations$]).pipe(
   map(([organisationContext, organisations]) => getParentIds(organisationContext, [organisationContext.id], organisations))
);
