import { combineQueries, createEntityQuery, Order } from '@datorama/akita';
import { parentIds$ } from 'context/OrganisationContext/query';
import { context$ as periodContext$ } from 'context/PeriodContext/query';
import { parseISO } from 'date-fns';
import { isEqual } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs';
import { standardsInReports$ } from 'state/Report/query';
import store from './store';

export const query = createEntityQuery(store);

export const entities$ = query.selectAll({
   sortBy: 'id',
   sortByOrder: Order.ASC,
});
export const activeEntity$ = query.selectActive();

export const entityCount$ = query.selectCount();

export const availableStandards$ = combineQueries([query.selectAll(), standardsInReports$]).pipe(
   map(([standards, standardsInReports]) => (standards ? standards.filter(({ key }) => !standardsInReports.includes(key)) : [])),
   distinctUntilChanged(isEqual)
);

export const assignedStandards$ = combineQueries([query.selectAll(), parentIds$, periodContext$]).pipe(
   map(([reportingStandards, parentIds, periodContext]) =>
      reportingStandards.filter(({ organisations }) =>
         organisations.some(
            ({ id, reportingstandard_organisation }) =>
               parentIds &&
               periodContext?.from &&
               periodContext?.to &&
               parentIds.includes(id) &&
               (!reportingstandard_organisation.from || parseISO(reportingstandard_organisation.from) <= parseISO(periodContext.from)) &&
               (!reportingstandard_organisation.to || parseISO(reportingstandard_organisation.to) >= parseISO(periodContext.to))
         )
      )
   )
);

export const loading$ = query.selectLoading();
