import useUserContext from 'context/UserContext/hook';
import { LocaleContext } from 'context/index';
import { setDefaultOptions } from 'date-fns';
import { getUserLocale } from 'get-user-locale';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';

async function resolve(locale_ = getUserLocale()) {
   let locale;

   try {
      locale = await import(`date-fns/locale/${locale_}/index.js`);
      return locale;
   } catch (e) {
      try {
         locale = await import(`date-fns/locale/${locale_.substring(0, 2)}/index.js`);
         return locale;
      } catch (e2) {
         locale = await import(`date-fns/locale/en-US/index.js`);
         return locale;
      }
   }
}

const LocaleContextComponent = function ({ children }) {
   const { context: userContext } = useUserContext();

   const [locale, setLocale] = useState();

   useEffect(() => {
      resolve(userContext?.locale)
         .then((locale_) => {
            setDefaultOptions({ locale: locale_ });
            return setLocale(locale_);
         })

         .catch((err) => {
            throw new Error(err);
         });
   }, [userContext?.locale]);

   return <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>;
};

LocaleContextComponent.propTypes = {
   children: PropTypes.node.isRequired,
};

export default LocaleContextComponent;
