import {
  context$,
  loading$,
  notifications$,
  organisations$,
  reportingPeriods$,
  reportingStandards$,
  userContextQuery,
} from 'context/UserContext/query';
import store from 'context/UserContext/store';
import OrganisationsService from 'state/Organisations/service';
import UsersService from 'state/User/service';

const api = new UsersService();
const organisationsAPI = new OrganisationsService();

const facade = {
  api,
  organisationsAPI,
  store,
  query: userContextQuery,
  context$,
  notifications$,
  organisations$,
  reportingStandards$,
  reportingPeriods$,
  loading$,
};

export default facade;
